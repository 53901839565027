import { render, staticRenderFns } from "./dashboard.vue?vue&type=template&id=72d62928&scoped=true&"
import script from "./dashboard.vue?vue&type=script&lang=js&"
export * from "./dashboard.vue?vue&type=script&lang=js&"
import style0 from "./dashboard.vue?vue&type=style&index=0&id=72d62928&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72d62928",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Notifications: require('/codebuild/output/src985175027/src/nuxt-thefutures/components/notifications/Index.vue').default,SideBar: require('/codebuild/output/src985175027/src/nuxt-thefutures/components/SideBar.vue').default,Toast: require('/codebuild/output/src985175027/src/nuxt-thefutures/components/Toast.vue').default,ToastNotification: require('/codebuild/output/src985175027/src/nuxt-thefutures/components/ToastNotification.vue').default})
