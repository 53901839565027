
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'ToastComponent',
  computed: {
    ...mapState('toast', ['snackbar']),
    isTop() {
      return this.snackbar.position.includes('top')
    },
    isRight() {
      return this.snackbar.position.includes('right')
    },
    isBottom() {
      return this.snackbar.position.includes('bottom')
    },
    isLeft() {
      return this.snackbar.position.includes('left')
    },
  },
  watch: {
    'snackbar.show'(val) {
      if (val) {
        setTimeout(() => {
          this.setShow({ show: false })
        }, this.snackbar.timeout)
      }
    },
  },
  methods: {
    ...mapMutations('toast', {
      setShow: 'SET_SHOW',
    }),
  },
}
