
export default {
  name: 'LoginLayout',
  computed: {
    planName() {
      if (this.$route.params && this.$route.params.plan) {
        return this.$route.params.plan.split('-')[0]
      }
      return ''
    },
  },
}
