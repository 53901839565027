
import sidebarMixin from '~/mixins/sidebarMixin'

export default {
  mixins: [sidebarMixin],

  data() {
    return {
      items: [
        { title: 'Home', icon: 'fas fa-person' },
        { title: 'About', icon: 'fas fa-person' },
      ],
      windowSize: {
        x: 0,
        y: 0,
      },
    }
  },

  mounted() {
    this.onResize()
  },

  methods: {
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
  },
}
