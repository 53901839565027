/* eslint-disable no-unreachable */
/**
 * Handles sidebar's functionality
 *
 */
import { $companyMSTicketCountByCompany } from '@/MS/company'

export default {
  data() {
    return {
      drawer: true,

      ticketCount: [],

      /**
       * state of mini sidebar
       */
      // mini: false,

      /**
       * error message to be displayed to the user
       */
      error: undefined,
    }
  },

  computed: {
    user() {
      return this.$auth.$storage.getState('user')
    },

    firstName() {
      if (!this.user) {
        return
      }

      if (this.user.name && this.user.name.length) {
        return this.user.name.split(' ')[0]
      }

      return 'Username'
    },

    isInvited() {
      return !!this.user.invitatorId
    },

    tickets() {
      if (Array.isArray(this.ticketCount) && this.ticketCount.length) {
        const data = {
          template: 0,
          open: 0,
          closed: 0,
          draft: 0,
          needRevision: 0,
        }

        const template = this.ticketCount.filter(
          (ticket) => ticket.status.toLowerCase() === 'template'
        )

        const draft = this.ticketCount.filter(
          (ticket) => ticket.status.toLowerCase() === 'draft'
        )

        const closed = this.ticketCount.filter(
          (ticket) => ticket.status.toLowerCase() === 'closed'
        )

        const open = this.ticketCount.filter((ticket) => {
          const status = [
            'being-checked',
            'we-have-questions',
            'on-queue',
            'awaiting-feedback',
            'wating-for-approval',
            'underway',
          ]

          return status.includes(ticket.status)
        })

        data.template = template[0] ? template[0].count : 0
        data.draft = draft[0] ? draft[0].count : 0
        data.closed = closed[0] ? closed[0].count : 0

        open.forEach((status) => {
          data.open += status.count

          if (
            status.status === 'waiting-for-approval' ||
            status.status === 'awaiting-feedback' ||
            status.status === 'we-have-questions'
          ) {
            data.needRevision += status.count
          }
        })

        return data
      }

      return {
        template: 0,
        open: 0,
        closed: 0,
        draft: 0,
        needRevision: 0,
      }
    },

    canSeeSetup() {
      if (!this.user) {
        return
      }

      if (!this.user.isEmployee) {
        return true
      }

      if (
        this.user.roles.includes('admin') ||
        this.user.roles.includes('is-pl')
      ) {
        return true
      }

      return false
    },

    /**
     * Links in Tickets Section
     *
     * visible only to all
     */
    ticketItems() {
      return [
        {
          title: 'Open',
          icon: 'fas fa-rocket',
          route: '/tickets/open',
          isLink: true,
        },
        {
          title: 'Closed',
          icon: 'fas fa-layer-group',
          route: '/tickets/closed',
          isLink: true,
        },
        {
          title: 'Draft',
          icon: 'fab fa-firstdraft',
          route: '/tickets/draft',
          isLink: true,
        },
        {
          title: 'Template',
          icon: 'fas fa-bookmark',
          route: '/tickets/template',
          isLink: true,
        },
      ]
    },

    /**
     * Links in Tools Section
     *
     * visible only to clients
     */
    setupItems() {
      return [
        {
          title: 'Visual Guides',
          icon: 'fas fa-award',
          route: '/brands',
          isExternal: false,
        },
        {
          title: 'Team',
          icon: 'fas fa-user-friends',
          route: '/team',
          isExternal: false,
        },
        {
          title: 'Knowledge Base',
          icon: 'fas fa-book-open',
          route: 'https://knowledgebase.thefutures.io/',
          isExternal: true,
        },
      ]
    },

    /**
     * Links in Tools Section
     *
     * visible only to employees
     */
    toolsItems() {
      if (!this.user) {
        return
      }

      // designer
      if (
        this.user.roles.includes('designer') &&
        !this.user.roles.includes('design-leader')
      ) {
        return [
          // {
          //   title: 'Create New User',
          //   icon: 'fas fa-user',
          //   route: '/users/create',
          //   isLink: true,
          // },
          {
            title: 'Kanban',
            icon: 'fab fa-trello',
            route: '/kanban',
            isLink: true,
          },
          {
            title: 'Employee Tools',
            icon: 'far fa-calendar-alt',
            route: 'https://intra.thefutures.io/',
            isLink: true,
            isExternal: true,
          },
          // {
          //   title: 'Creative Team Dashboard',
          //   icon: 'fas fa-pencil-ruler',
          //   route: '/desigr/assigned',
          //   isLink: true,
          // },
          // {
          //   title: 'Client List',
          //   icon: 'fas fa-list-alt',
          //   route: '/cx/clients',
          //   isLink: true,
          // },
          // {
          //   title: 'View As',
          //   icon: 'fa fa-eye',
          //   route: '/view-as',
          //   isLink: true,
          // },
        ]
      }

      // design leader
      if (
        !this.user.roles.includes('designer') &&
        this.user.roles.includes('design-leader')
      ) {
        return [
          // {
          //   title: 'Create New User',
          //   icon: 'fas fa-user',
          //   route: '/users/create',
          //   isLink: true,
          // },
          {
            title: 'Kanban',
            icon: 'fab fa-trello',
            route: '/kanban',
            isLink: true,
          },
          {
            title: 'Employee Tools',
            icon: 'far fa-calendar-alt',
            route: 'https://intra.thefutures.io/',
            isLink: true,
            isExternal: true,
          },
          {
            title: 'Creative Team Dashboard',
            icon: 'fas fa-pencil-ruler',
            route: '/desigr/assigned',
            isLink: true,
          },
          {
            title: 'Client List',
            icon: 'fas fa-list-alt',
            route: '/cx/clients',
            isLink: true,
          },
          // {
          //   title: 'View As',
          //   icon: 'fa fa-eye',
          //   route: '/view-as',
          //   isLink: true,
          // },
        ]
      }

      // pl / admin
      return [
        {
          title: 'Create New User',
          icon: 'fas fa-user',
          route: '/users/create',
          isLink: true,
        },
        {
          title: 'Kanban',
          icon: 'fab fa-trello',
          route: '/kanban',
          isLink: true,
        },
        {
          title: 'Employee Tools',
          icon: 'far fa-calendar-alt',
          route: 'https://intra.thefutures.io/',
          isLink: true,
          isExternal: true,
        },
        {
          title: 'Creative Team Dashboard',
          icon: 'fas fa-pencil-ruler',
          route: '/desigr/assigned',
          isLink: true,
        },
        {
          title: 'Client List',
          icon: 'fas fa-list-alt',
          route: '/cx/clients',
          isLink: true,
        },
        {
          title: 'View As',
          icon: 'fa fa-eye',
          route: '/view-as',
          isLink: true,
        },
      ]
    },

    /**
     * Links in settings Section
     *
     * visible to all users
     */
    settingsItems() {
      // designer
      if (!this.user) {
        return
      }

      if (
        this.user.roles.includes('designer') &&
        !this.user.roles.includes('design-leader')
      ) {
        return [
          // {
          //   title: 'My Account',
          //   icon: 'far fa-user-circle',
          //   route: '/myaccount',
          //   isLink: true,
          //   hideOnInvitedUser: false,
          // },
          // /**
          //  * Hidden to invited users
          //  *
          //  */
          // {
          //   title: 'Billing',
          //   icon: 'far fa-credit-card',
          //   route: '/billing-settings',
          //   isLink: true,
          //   hideOnInvitedUser: true,
          // },
          // {
          //   title: 'Support',
          //   icon: 'fa fa-info-circle',
          //   route: 'https://knowledgebase.thefutures.io/ticket',
          //   isLink: true,
          //   isExternal: true,
          //   hideOnInvitedUser: false,
          // },
          {
            title: 'Logout',
            icon: 'fas fa-sign-out-alt',
            route: '/logout',
            isLink: true,
            hideOnInvitedUser: false,
          },
        ]
      }

      // all other users

      return [
        {
          title: 'My Account',
          icon: 'far fa-user-circle',
          route: '/myaccount',
          isLink: true,
          hideOnInvitedUser: false,
        },
        /**
         * Hidden to invited users
         *
         */
        {
          title: 'Billing',
          icon: 'far fa-credit-card',
          route: '/billing-settings',
          isLink: true,
          hideOnInvitedUser: true,
        },
        {
          title: 'Support',
          icon: 'fa fa-info-circle',
          route: 'https://knowledgebase.thefutures.io/ticket',
          isLink: true,
          isExternal: true,
          hideOnInvitedUser: false,
        },
        {
          title: 'Logout',
          icon: 'fas fa-sign-out-alt',
          route: '/logout',
          isLink: true,
          hideOnInvitedUser: false,
        },
      ]
    },

    userImage() {
      const authUser = this.$auth.$storage.getState('user')

      return authUser ? authUser.image : ''
    },
  },

  // watch: {
  //   mini(val) {
  //     this.$auth.$storage.setUniversal('mini', val)
  //   },
  // },

  async mounted() {
    try {
      await this.init()
    } catch (error) {
      this.error = error
    }
  },

  methods: {
    async init() {
      // this.setMiniState()
      // this.setDropdownsState()

      // watch ticket dropdown state
      // this.$watch(
      //   'items',
      //   (val) => {
      //     this.$auth.$storage.setUniversal(
      //       'sidebarTicketSection',
      //       val[0].active
      //     )
      //     this.$auth.$storage.setUniversal('sidebarSetupSection', val[1].active)
      //   },
      //   {
      //     deep: true,
      //   }
      // )

      // watch setttings dropdown state
      // this.$watch(
      //   'settingsItems',
      //   (val) => {
      //     this.$auth.$storage.setUniversal(
      //       'sidebarSettingsSetupSection',
      //       val[0].active
      //     )
      //   },
      //   {
      //     deep: true,
      //   }
      // )

      // watch tools dropdown state
      // if (this.user.isEmployee) {
      //   this.$watch(
      //     'toolsItems',
      //     (val) => {
      //       this.$auth.$storage.setUniversal(
      //         'sidebarToolsSetupSection',
      //         val[0].active
      //       )
      //     },
      //     {
      //       deep: true,
      //     }
      //   )
      // }

      this.ticketCount = await this.getTicketCount()
    },

    /**
     * chekf if the given section can be displayed
     *
     * returns false otherwise
     *
     * @param string sectionTitle
     *
     * @return boolean
     */
    isSectionVisible(sectionTitle) {
      // to avoid TypeError in line 334 (when loging-out)
      if (!this.user) {
        return false
      }

      if (this.user.roles.includes('design-leader')) {
        if (sectionTitle === 'Tickets') {
          return false
        }

        if (sectionTitle === 'Setup') {
          return true
        }
      }

      if (
        this.user.roles.includes('admin') ||
        this.user.roles.includes('content-manager')
      ) {
        return true
      }

      return false
    },

    /**
     * chekf if the given item can be displayed
     *
     * returns true otherwise
     *
     * @param string itemTitle
     *
     * @return boolean
     */
    isItemVisible(itemTitle) {
      if (itemTitle === 'Creative Team Dashboard') {
        return (
          this.user.roles.includes('design-leader') ||
          this.user.roles.includes('content-manager')
        )
      }

      if (itemTitle === 'View As') {
        return (
          this.user.roles.includes('admin') ||
          this.user.roles.includes('content-manager')
        )
      }

      if (itemTitle === 'Client List') {
        return (
          this.user.roles.includes('admin') ||
          this.user.roles.includes('content-manager')
        )
      }

      return true
    },

    /**
     * returns false if user is invited and the option is meant to be hidden to invited users.
     * returns true otherwise
     *
     * @param boolean isHidden
     *
     * @return boolean
     */
    isVisibleToInvitedUsers(isHidden) {
      return !(this.isInvited && isHidden)
    },

    /**
     * get ticket count, ordered by status
     *
     * user should be defined in component
     * so the companyId is accesible
     *
     * @return array
     */
    async getTicketCount() {
      const payload = {
        userId: this.user.sub,
        authToken: this.$auth.$storage.getUniversal('_token.cookie'),
        companyId: this.user.companyId,
      }

      const response = await $companyMSTicketCountByCompany(payload)

      if (!response.success && response.message !== 'Empty list!')
        throw new Error(
          `Error getting ticket count for company: ${this.user.companyId}: ${response.message}`
        )

      return response.data
    },

    /**
     * gets the mini value in storage. If not set, sets it
     * to false
     */
    setMiniState() {
      const isMini = this.$auth.$storage.getUniversal('mini')

      this.mini = isMini ?? false
    },

    /**
     * gets dropdowns state in storage. If not set, sets them
     * to false
     */
    setDropdownsState() {
      const sidebarTicketSection = this.$auth.$storage.getUniversal(
        'sidebarTicketSection'
      )
      const sidebarSetupSection = this.$auth.$storage.getUniversal(
        'sidebarSetupSection'
      )
      const sidebarToolsSection = this.$auth.$storage.getUniversal(
        'sidebarToolsSection'
      )
      const sidebarSettingsSection = this.$auth.$storage.getUniversal(
        'sidebarSettingsSection'
      )

      this.items[0].active = sidebarTicketSection ?? false
      this.items[1].active = sidebarSetupSection ?? false
      this.toolsItems[0].active = sidebarToolsSection ?? false
      this.settingsItems[0].active = sidebarSettingsSection ?? false
    },

    showCount(elementName) {
      const status = ['open', 'closed', 'template', 'draft']

      return status.includes(elementName.toLowerCase())
    },

    retryLoad() {
      this.init()
    },
  },
}
