export const state = () => ({
  notifications: [],
})

export const getters = {
  // getCounter(state) {
  //   return state.counter
  // },
  unreadNotifications: (state) => {
    if (state.notifications.length) {
      return state.notifications.filter((notification) => !notification.readAt)
        .length
    } else {
      return 0
    }
  },
}

export const mutations = {
  setData(state, payload) {
    payload.forEach((element) => {
      state[element.property] = element.data
    })
  },
}

export const actions = {
  async fetchNotifications({ state, commit }) {
    const user = this.$auth.$storage.getState('user')
    const queryUserId =
      user.roles.includes('is-pl') || user.roles.includes('admin')
        ? 7
        : user.sub

    const payload = {
      userId: this.$auth.$storage.getState('user').sub,
      authToken: this.$auth.$storage.getUniversal('_token.cookie'),
      query: `userId=${queryUserId}`,
    }

    const { success, data, message } = await this.$notificationMS.get(payload)

    if (!success) {
      throw new Error(`Error getting notifications: ${message}`)
    }

    commit('setData', [{ data, property: 'notifications' }])

    return data
  },
}
