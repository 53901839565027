export default ({ app, $axios, $config }, inject) => {
  async function store({ userId, authToken, payload }) {
    return await $axios.$post(`${$config.baseURL}/public/files`, payload, {
      headers: {
        Authorization: `${authToken}`,
        'User-Id': `${userId}`,
      },
    })
  }

  async function update({ userId, authToken, payload, fileId }) {
    return await $axios.$put(
      `${$config.baseURL}/public/files/${fileId}`,
      payload,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  async function list({ userId, authToken, query }) {
    return await $axios.$get(`${$config.baseURL}/public/files?${query}`, {
      headers: {
        Authorization: `${authToken}`,
        'User-Id': `${userId}`,
      },
    })
  }

  async function listAnnotationsByTicketId({ userId, authToken, ticketId }) {
    return await $axios.$get(
      `${$config.baseURL}/public/files/annotations?ticket=${ticketId}`,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  async function showByTicket({ userId, authToken, ticketId }) {
    return await $axios.$get(
      `${$config.baseURL}/public/files?ticket=${ticketId}`,

      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }
  async function approveSeveralFiles({ userId, authToken, payload }) {
    return await $axios.$patch(
      `${$config.baseURL}/public/files/approve/several`,
      payload,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  async function approveSeveral({
    userId,
    authToken,
    user,
    ticketId,
    payload,
  }) {
    const { files } = payload
    const { autoManagement } = user
    const filesToSend = files.map((file) => {
      return {
        id: file.id,
        type: file.type,
        revision: file.revision,
        hidden: file.hidden,
        favouriteFile: file.favouriteFile,
      }
    })

    const responseApprove = await $axios.$patch(
      `${$config.baseURL}/public/files/approve/several`,
      filesToSend,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )

    if (!responseApprove.success) {
      throw new Error(
        `Error in update several files: ${responseApprove.message}`
      )
    }

    if (!autoManagement) {
      const response = await $axios.$patch(
        `${$config.baseURL}/public/tickets/${ticketId}`,
        {
          status: 'waiting-for-approval',
        },
        {
          headers: {
            Authorization: `${authToken}`,
            'User-Id': `${userId}`,
          },
        }
      )

      if (!response.success) {
        throw new Error(
          `Couldn't move ticket to waiting for approval on approveFiles: ${response.message}`
        )
      }
    } else {
      const response = await $axios.$patch(
        `${$config.baseURL}/public/tickets/${ticketId}`,
        {
          status: 'waiting-for-approval',
        },
        {
          headers: {
            Authorization: `${authToken}`,
            'User-Id': `${userId}`,
          },
        }
      )

      if (!response.success) {
        throw new Error(
          `Couldn't move ticket to awaiting feedback on approveFiles: ${response.message}`
        )
      }
    }

    return {
      success: true,
      status: autoManagement ? 'awaiting-feedback' : 'waiting-for-approval',
    }
  }

  async function listAnnotations({ userId, authToken, ticketId }) {
    return await $axios.$get(
      `${$config.baseURL}/public/files/annotations?ticketId=${ticketId}`,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  async function storeAnnotation({ userId, authToken, payload }) {
    return await $axios.$post(
      `${$config.baseURL}/public/files/annotations`,
      payload,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  async function updateAnnotation({ userId, authToken, payload }) {
    return await $axios.$put(
      `${$config.baseURL}/public/files/${payload.fileId}/annotations`,
      payload,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  async function deleteAnnotation({ userId, authToken, fileId }) {
    return await $axios.$delete(
      `${$config.baseURL}/public/files/${fileId}/annotations`,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  async function uploadBase64({ userId, authToken, payload }) {
    return await $axios.$post(
      `${$config.baseURL}/files/upload-base64`,
      payload,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  async function fileUpload({ userId, authToken, payload }) {
    return await $axios.$post(
      `${$config.baseURL}/public/files/upload`,
      payload,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  async function qualityTest({ userId, authToken, fileId }) {
    return await $axios.$get(
      `${$config.baseURL}/public/quality/tests?file=${fileId}`,

      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  async function zipDownload({ userId, authToken, ticketId, submissionId }) {
    return await $axios.$post(
      `${$config.baseURL}/public/quality/tickets/${ticketId}/submissions/${submissionId}/zipdownload`,
      {},
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  async function deleteFile({ userId, authToken, fileId }) {
    return await $axios.$delete(`${$config.baseURL}/public/files/${fileId}`, {
      headers: {
        Authorization: `${authToken}`,
        'User-Id': `${userId}`,
      },
    })
  }

  async function qualityTests({ userId, authToken, id }) {
    return await $axios.$get(`${$config.baseURL}/public/quality/tests/${id}`, {
      headers: {
        Authorization: `${authToken}`,
        'User-Id': `${userId}`,
      },
    })
  }

  async function chatFileUpload({ userId, authToken, payload }) {
    return await $axios.$post(
      `${$config.baseURL}/public/files/comment/upload/`,
      payload,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  /**
   * generate thumbnails for files in the qualityMS
   *
   * @params userId Number
   * @params authToken String
   * @params payload Object { fileId }
   * @returns object
   */

  async function runTests({ userId, authToken, payload }) {
    return await $axios.$post(
      `${$config.baseURL}/files/run/${payload.fileId}`,
      {},
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  const fileMS = {
    store,
    list,
    update,
    listAnnotationsByTicketId,
    showByTicket,
    approveSeveralFiles,
    approveSeveral,
    listAnnotations,
    storeAnnotation,
    updateAnnotation,
    deleteAnnotation,
    uploadBase64,
    zipDownload,
    deleteFile,
    qualityTests,
    fileUpload,
    qualityTest,
    chatFileUpload,
    runTests,
  }

  inject('fileMS', fileMS)
}
