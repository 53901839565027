/* eslint-disable no-undef */
/* eslint-disable camelcase */
import { set } from 'vue'
import { cloneDeep } from 'lodash'

export const state = () => ({
  clientFiles: [],
  deliverableFiles: [],
  timeSelected: 0,
  annotationsVideo: [],
  internalFiles: [],
  markers: [],
  filesAmApprove: [],
  actualSubmission: undefined,
  allFiles: [],
})

export const getters = {
  getUser: (state) => state.user,
}

export const mutations = {
  setData(state, payload) {
    payload.forEach((element) => {
      state[element.property] = element.data
    })
  },

  updateFileProperty(state, payload) {
    const index = state.deliverableFiles.findIndex(
      (file) => file.id === payload.file.id
    )

    state.deliverableFiles[index].favouriteFile = payload.data
  },

  pushAnnotations(state, payload) {
    state.annotationsVideo.push(payload.annotation)
  },

  addInternalFiles(state, payload) {
    state.internalFiles = state.internalFiles.concat(payload.files)
  },

  resetImgDeliverableFiles(state) {
    state.deliverableFiles.forEach((item) => {
      item.active = false
      if (item.files_relationships) {
        item.files_relationships.forEach((itemRel) => {
          itemRel.active = false
        })
      }
    })
  },

  setImgActiveDeliverableFiles(state, payload) {
    payload.file.active = true
    if (payload.file.parentFileId) {
      const idx = state.deliverableFiles.findIndex(
        (file) => file.id === payload.file.parentFileId
      )
      const childrenIndex = state.deliverableFiles[
        idx
      ].files_relationships.findIndex(
        (childrenFile) => childrenFile.id === payload.file.id
      )

      set(state.deliverableFiles[idx], 'show_children', true)
      set(
        state.deliverableFiles[idx].files_relationships,
        childrenIndex,
        payload.file
      )
    } else {
      const idx = state.deliverableFiles.findIndex(
        (file) => file.id === payload.file.id
      )

      set(state.deliverableFiles, idx, payload.file)
    }
  },

  toggleShowChildrenFiles(state, id) {
    const index = state.deliverableFiles.findIndex(
      (element) => element.id === id
    )

    state.deliverableFiles[index].show_children =
      !state.deliverableFiles[index].show_children
  },

  addFileToApprove(state, payload) {
    state.filesAmApprove.push(payload.file)

    const index = state.deliverableFiles.findIndex(
      (element) => element.id === payload.file.id
    )

    state.deliverableFiles[index].hidden = 1
  },

  removeFileApproved(state, payload) {
    state.filesAmApprove = state.filesAmApprove.filter(
      (file) => file.id !== payload.file.id
    )
  },

  updateSingleFile(state, payload) {
    const fileToUpdate = payload.file
    const idx = state.deliverableFiles.findIndex(
      (file) => file.id === fileToUpdate.id
    )

    set(state.deliverableFiles, idx, fileToUpdate)
  },

  resetFileAMApprove(state) {
    state.filesAmApprove = []
  },

  setActualSubmission(state, payload) {
    state.actualSubmission = payload.actualSubmission
  },

  setDeliverableFilesData(state, payload) {
    state.deliverableFiles = payload.deliverableFiles
  },

  updateCheckDeliverableFiles(state) {
    const filesUpdated = state.deliverableFiles.map((item) => {
      // item.active = false
      state.filesAmApprove.forEach((fileApproved) => {
        if (item.id === fileApproved.id) {
          item.hidden = 0
        }
      })
      return item
    })
    state.deliverableFiles = filesUpdated
  },

  SET_VIDEO_TIME_SELECTED(state, payload) {
    state.timeSelected = payload
  },

  ADD_VIDEO_MARKER(state, payload) {
    state.markers.push(payload)
  },

  REMOVE_VIDEO_MARKER(state, payload) {
    state.markers = payload
  },

  SET_VIDEO_ANNOTATION(state, payload) {
    state.annotationsVideo = payload
  },
}

export const actions = {
  async addAnnotationVideo({ commit, state }, payload) {
    const authUser = this.$auth.$storage.getState('user')
    const payloadPetition = {
      userId: authUser.sub,
      authToken: this.$auth.$storage.getUniversal('_token.cookie'),
      payload: payload.data.annotationData,
    }

    const response = await this.$fileMS.updateAnnotation(payloadPetition)

    if (!response.success) {
      throw new Error(`Error saving changes: ${response.message}`)
    }

    if (payload.type === 'add') {
      commit('ADD_VIDEO_MARKER', payload.mark)
    }

    // const response = await axios.put(
    //   '/api/annotations/' + payload.activeId,
    //   payload.data
    // )
    // if (response.status === 200) {
    //   if (payload.type === 'add') {
    //     $('#videoAnnotationModal').modal('hide')
    //     toastr.success('Comment Added', 'Success! ✌', {
    //       showDuration: '300',
    //       hideDuration: '1000',
    //       showEasing: 'swing',
    //       hideEasing: 'linear',
    //       showMethod: 'fadeIn',
    //       hideMethod: 'fadeOut',
    //       timeOut: '3000',
    //       extendedTimeOut: '1000',
    //     })
    //     commit('ADD_VIDEO_MARKER', payload.mark)
    //   } else if (payload.type === 'delete') {
    //     $('#videoAnnotationModal').modal('hide')
    //     toastr.success('Comment was Deleted', 'Success! ✌', {
    //       showDuration: '300',
    //       hideDuration: '1000',
    //       showEasing: 'swing',
    //       hideEasing: 'linear',
    //       showMethod: 'fadeIn',
    //       hideMethod: 'fadeOut',
    //       timeOut: '3000',
    //       extendedTimeOut: '1000',
    //     })
    //   }
    // } else {
    //   toastr.error(
    //     'Ooops! There was an problem getting annotations, please refresh the page'
    //   )
    // }
    return response
  },

  async fetchFiles({ commit }, query) {
    const authUser = this.$auth.$storage.getState('user')
    const payload = {
      userId: authUser.sub,
      authToken: this.$auth.$storage.getUniversal('_token.cookie'),
      query,
    }

    const response = await this.$fileMS.list(payload)

    if (!response.success) {
      throw new Error(`Error getting files: ${response.message}`)
    }

    return response.data
  },

  async addFileToFavourites({ commit }, payload) {
    // console.log('payload in action', payload)
    // // const { file } = payload
    const fileUpdated = payload

    // console.log(fileUpdated)

    // fileUpdated.favouriteFile = !payload.favouriteFile ? 1 : 0

    commit('updateFileProperty', {
      property: 'favouriteFile',
      data: !payload.favouriteFile ? 1 : 0,
      file: payload,
    })

    // console.log('fileUpdatedAfter', fileUpdated)

    const filePayload = {
      favouriteFile: fileUpdated.favouriteFile,
      hidden: fileUpdated.hidden,
      type: fileUpdated.type,
      revision: fileUpdated.revision,
    }

    // // const url = '/api/files/' + file.id

    commit('updateSingleFile', { file: fileUpdated })

    // // const response = await this.$axios.put(url, {
    // //   file: fileUpdated,
    // // })

    // // console.log(response)

    const authUser = this.$auth.$storage.getState('user')
    const payloadPetition = {
      userId: authUser.sub,
      authToken: this.$auth.$storage.getUniversal('_token.cookie'),
      payload: filePayload,
      fileId: fileUpdated.id,
    }

    const response = await this.$fileMS.update(payloadPetition)

    if (!response.success) {
      throw new Error(`Error updating file ${payload.id}: ${response.message}`)
    }

    if (fileUpdated.favouriteFile) {
      return 'Asset has been added to favoutire files'
    } else {
      return 'Asset has been removed of favoutire files'
    }
  },

  async updateApprovedFiles({ state, commit, rootGetters }, payload) {
    const filesCloned = cloneDeep(state.filesAmApprove)
    const files = filesCloned.reduce((result, file) => {
      file.hidden = 0
      result.push(file)
      if (file.files_relationships.length) {
        file.isParent = true
        file.files_relationships.forEach((children_file) => {
          children_file.hidden = 0
          children_file.parentDownloadUrl = file.downloadUrl
          result.push(children_file)
        })
      }

      return result
    }, [])

    const data = {
      files,
    }

    try {
      const authUser = this.$auth.$storage.getState('user')
      const payloadApproveFiles = {
        userId: authUser.sub,
        user: rootGetters['user/getUser'],
        authToken: this.$auth.$storage.getUniversal('_token.cookie'),
        ticketId: payload.ticket.id,
        payload: data,
      }

      // const response = await this.$axios.post(url, data)

      const response = await this.$fileMS.approveSeveral(payloadApproveFiles)

      if (!response.success) {
        throw new Error(`Invalid request to update ticket: ${response.message}`)
      }

      // if (response.data.success === true || response.data.success === 'true') {
      commit('updateCheckDeliverableFiles')
      // // commit('CLEAN_APPROVED_FILES')
      commit('setData', [{ property: 'filesAmApprove', data: [] }])

      // // toastr.success('Files has been approved!', 'Success! ✌', {
      // //   showDuration: '300',
      // //   hideDuration: '1000',
      // //   showEasing: 'swing',
      // //   hideEasing: 'linear',
      // //   showMethod: 'fadeIn',
      // //   hideMethod: 'fadeOut',
      // //   timeOut: '3000',
      // //   extendedTimeOut: '1000',
      // // })

      const payloadTicket = {
        field: 'status',
        data:
          response.status === 'waiting-for-approval'
            ? 'waiting-for-approval'
            : 'awaiting-feedback',
      }

      commit('ticket/addDataTicket', payloadTicket, { root: true })

      return true
    } catch (error) {
      // eslint-disable-next-line no-console
      // console.error('There was an error approving files', error)

      const user = this.$auth.$storage.getState('user')

      this.$sentry.setContext('user', user)
      this.$sentry.setTag('context', 'files store')
      this.$sentry.captureException(error)

      return false
      // toastr.error('There was an error approving files')
      // toastr.success('Files has been approved!', 'Success! ✌', {
      //   showDuration: '300',
      //   hideDuration: '1000',
      //   showEasing: 'swing',
      //   hideEasing: 'linear',
      //   showMethod: 'fadeIn',
      //   hideMethod: 'fadeOut',
      //   timeOut: '3000',
      //   extendedTimeOut: '1000',
      // })
    }
  },
}
