import { set } from 'vue'

export const state = () => ({
  ticket: false,
  artworkTexts: [],
  // artworkTexts: [
  //   { id: 27, copy: 'test' },
  //   { id: 28, copy: 'test' },
  // ],
  modalEditTicket: {
    title: '',
    type: '',
    field: '',
    val: '',
    options: [],
    endpoint: '',
    placeholder: '',
    parentId: null,
    input: '',
  },
  transactionSelected: {},
  annotations: [],
  cannedResponses: [],
  subscriptions: [],
})

export const getters = {
  ticketId: (state) => state.ticket.id,
}

export const mutations = {
  setData(state, payload) {
    payload.forEach((element) => {
      state[element.property] = element.data
    })
  },

  addDataTicket(state, { data, field }) {
    // alias of Vue.set()
    set(state.ticket, field, data)
  },

  setDataEditModal(state, payload) {
    state.modalEditTicket = {
      ...payload,
      endpoint: `/api/tickets/${state.ticket.id}`,
    }
  },

  updateInputEditModal(state, payload) {
    state.modalEditTicket.input = payload
  },

  pushToArrayProperty(state, payload) {
    payload.forEach((element) => {
      state[element.property].push(element.data)
    })
  },

  deleteCopy(state, index) {
    state.artworkTexts.splice(index, 1)
  },

  spliceCannedResponse(state, id) {
    const index = state.cannedResponses.findIndex(
      (element) => element.index === index
    )

    state.cannedResponses.splice(index, 1)
  },

  ADD_CANNED_RESPONSE(state, payload) {
    state.cannedResponses.push({
      ...payload.cannedResponse,
      id: payload.id,
    })
  },

  updateCannedResponse(state, payload) {
    const index = state.cannedResponses.findIndex(
      (element) => element.id === payload.id
    )
    state.cannedResponses.splice(index, 1, payload)
  },

  // updateCheckAnnotation(state, payload) {
  //   state.
  // }

  UPDATE_ANNOTATIONS(state, payload) {
    set(state.annotations, payload.index, payload.data)
  },

  ADD_ANNOTATIONS(state, payload) {
    state.annotations.push(payload.annotations)
  },
}

export const actions = {
  async getTicket({ state, commit }, ticketId) {
    const authUser = this.$auth.$storage.getState('user')
    const payload = {
      userId: authUser.sub,
      authToken: this.$auth.$storage.getUniversal('_token.cookie'),
      ticketId,
    }

    const response = await this.$ticketMS.show(payload)

    if (!response.success) {
      throw new Error(`Error getting ticket data: ${response.message}`)
    }

    commit('setData', [{ property: 'ticket', data: response.data }])
  },

  async updateTicket({ state, commit }, payload) {
    // try {
    const authUser = this.$auth.$storage.getState('user')
    const payloadPetition = {
      userId: authUser.sub,
      authToken: this.$auth.$storage.getUniversal('_token.cookie'),
      id: state.ticket.id,
      payload: { [payload.field]: payload.data },
    }

    const response = await this.$ticketMS.put(payloadPetition)

    if (!response.success) {
      throw new Error(`Error updating ticket: ${response.message}`)
    }

    // if (response.data.success === true || response.data.success === 'true') {
    const payloadCommit = {
      field: payload.field,
      data: payload.data,
    }

    commit('addDataTicket', payloadCommit)

    return true

    // toastr.success(payload.message, 'Success! ✌', {
    //   showDuration: '300',
    //   hideDuration: '1000',
    //   showEasing: 'swing',
    //   hideEasing: 'linear',
    //   showMethod: 'fadeIn',
    //   hideMethod: 'fadeOut',
    //   timeOut: '3000',
    //   extendedTimeOut: '1000',
    // })
    // if (payload.modal) {
    //   $('#' + payload.modal).modal('hide')
    // }
    // } else {
    //   toastr.error(
    //     payload.error ? payload.error : 'There was an error updating ticket'
    //   )
    // }
    // } catch (error) {
    //   console.error(
    //     payload.error ? payload.error : 'There was an error updating ticket',
    //     error
    //   )
    //   toastr.error(
    //     payload.error ? payload.error : 'There was an error updating ticket'
    //   )
    // }
  },
  async sendRevision({ state, commit }, payload) {
    try {
      const payloadPetition = {
        userId: payload.userId,
        authToken: this.$auth.$storage.getUniversal('_token.cookie'),
        payload: 'being-checked',
        id: state.ticket.id,
      }

      const response = await this.$ticketMS.changeStatusCustom(payloadPetition)

      if (response.success === true || response.success === 'true') {
        const payload = {
          field: 'status',
          data: 'being-checked',
        }

        commit('ADD_DATA_TICKET', payload)
        return true
      } else {
        throw new Error(`Error updating ticket: ${response.message}`)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      // console.error(error)

      const user = this.$auth.$storage.getState('user')

      this.$sentry.setContext('user', user)
      this.$sentry.setTag('context', 'ticket store')
      this.$sentry.captureException(error)

      commit(
        'toast/SET_TOAST',
        {
          message: error.message,
          type: 'error',
          timeout: 5000,
        },
        { root: true }
      )

      return false
    }
  },

  async updateTicketStatus({ state, commit }, payload) {
    try {
      const payloadPetition = {
        userId: payload.userId,
        authToken: this.$auth.$storage.getUniversal('_token.cookie'),
        payload: 'being-checked',
        id: state.ticket.id,
      }

      const response = await this.$ticketMS.changeStatusCustom(payloadPetition)

      if (response.success === true || response.success === 'true') {
        const commitPayload = {
          field: 'status',
          data: 'being-checked',
        }

        commit('ADD_DATA_TICKET', commitPayload)

        return true
      }

      throw new Error(
        `Invalid request to update ticket status ${response.message}`
      )
    } catch (error) {
      // eslint-disable-next-line no-console
      // console.error(error)

      const user = this.$auth.$storage.getState('user')

      this.$sentry.setContext('user', user)
      this.$sentry.setTag('context', 'ticket store')
      this.$sentry.captureException(error)

      commit(
        'toast/SET_TOAST',
        {
          message: error.message,
          type: 'error',
          timeout: 5000,
        },
        { root: true }
      )

      return false
    }
  },
}
