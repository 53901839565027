import axios from 'axios'
const BASE_URL = process.env.NUXT_ENV_API_URL

/**
 * Gets tickets count by company
 *
 * @param number userId
 * @param string authToken
 * @param number companyId
 *
 * @return object
 */
const $companyMSTicketCountByCompany = async ({
  userId,
  authToken,
  companyId,
}) => {
  const { data } = await axios.get(
    `${BASE_URL}/companies/tickets-count?company=${companyId}`,
    {
      headers: {
        Authorization: `${authToken}`,
        'User-Id': `${userId}`,
      },
    }
  )

  return data
}

/**
 * GET TAGS BY COMPANY
 *
 * @param string authToken
 * @param number companyId
 *
 * @return object
 */
const $companyMSTags = async ({ authToken, companyId }) => {
  const { data } = await axios.get(
    `${BASE_URL}/public/tags?createdByCompany=${companyId}`,
    {
      headers: {
        Authorization: `${authToken}`,
      },
    }
  )

  return data
}

/**
 * Creates a new tag
 *
 * @param string authToken
 * @param payload payload
 *
 * @return object
 */
const $companyMSPostTags = async ({ authToken, payload }) => {
  const { data } = await axios.post(`${BASE_URL}/public/tags`, payload, {
    headers: {
      Authorization: `${authToken}`,
    },
  })

  return data
}

/**
 * Deletes a tag
 *
 * @param string authToken
 * @param number tagId
 *
 * @return object
 */
const $companyMSDeleteTags = async ({ authToken, tagId }) => {
  const { data } = await axios.delete(`${BASE_URL}/public/tags/${tagId}`, {
    headers: {
      Authorization: `${authToken}`,
    },
  })

  return data
}

/**
 * Creates Company
 *
 * @param number companyId
 * @param string authToken
 * @param object data
 *
 * @return object
 */
const $companyMSCreateCompany = async ({ authToken, body }) => {
  const { data } = await axios.post(`${BASE_URL}/companies`, body, {
    headers: {
      Authorization: `${authToken}`,
    },
  })

  return data
}

/**
 * Update Company
 *
 * @param number companyId
 * @param string authToken
 * @param object data
 *
 * @return object
 */
const $companyMSUpdateCompany = async ({ companyId, authToken, data }) => {
  const response = await axios.patch(
    `${BASE_URL}/companies/${companyId}`,
    data,
    {
      headers: {
        Authorization: `${authToken}`,
      },
    }
  )

  if (!response.data.success) {
    throw new Error(`Error on update company: ${response.data.message}`)
  }

  return response.data.data
}

/**
 * Update Company
 *
 * @param number userId
 * @param string authToken
 *
 * @return object
 */
const $companyMSListIndustries = async ({ userId, authToken }) => {
  const response = await axios.get(`${BASE_URL}/public/companies/industries`, {
    headers: {
      Authorization: `${authToken}`,
    },
  })

  if (!response.data.success) {
    throw new Error(`Error getting industries: ${response.data.message}`)
  }

  return response.data.data
}

/**
 * shows given brand
 *
 * @param number userId
 * @param string authToken
 * @param number brandId
 *
 * @return object
 */
const $companyMSShowBrand = async ({ userId, authToken, brandId }) => {
  const { data } = await axios.get(
    `${BASE_URL}/public/companies/brands/${brandId}`,
    {
      headers: {
        Authorization: `${authToken}`,
        'User-Id': `${userId}`,
      },
    }
  )

  return data
}

/**
 * gets users of given company
 *
 * @param number userId
 * @param string authToken
 * @param number companyId
 *
 * @return object
 */
const $companyMSUsersOfCompany = async ({ userId, authToken, companyId }) => {
  const { data } = await axios.get(
    `${BASE_URL}/public/users?company=${companyId}`,
    {
      headers: {
        Authorization: `${authToken}`,
        'User-Id': `${userId}`,
      },
    }
  )

  return data
}

/**
 * gets update given brand asset
 *
 * @param number userId
 * @param string authToken
 * @param number brandId
 * @param object payload
 *
 * @return object
 */
const $companyMSUpdateBrandAssets = async ({
  userId,
  authToken,
  brandId,
  payload,
}) => {
  const { data } = await axios.put(
    `${BASE_URL}/public/companies/brands/${brandId}/assets`,
    payload,
    {
      headers: {
        Authorization: `${authToken}`,
        'User-Id': `${userId}`,
      },
    }
  )

  return data
}

/**
 * Deletes a brand asset
 *
 * @param number userId
 * @param string authToken
 * @param number brandId
 * @param object payload
 *
 * @return object
 */
const $companyMSDestroyBrandAssets = async ({
  userId,
  authToken,
  brandId,
  payload,
}) => {
  const { data } = await axios.post(
    `${BASE_URL}/public/companies/brands/${brandId}/assets-delete`,
    payload,
    {
      headers: {
        Authorization: `${authToken}`,
        'User-Id': `${userId}`,
      },
    }
  )

  return data
}

/**
 * List Brands
 *
 * @param number userId
 * @param string authToken
 * @param number companyId
 *
 * @return object
 */
const $companyMSListBrands = async ({ userId, authToken, companyId }) => {
  const { data } = await axios.get(
    `${BASE_URL}/public/companies/brands?company=${companyId}`,
    {
      headers: {
        Authorization: `${authToken}`,
        'User-Id': `${userId}`,
      },
    }
  )

  if (!data.success) {
    throw new Error(`Error getting brands for company ${companyId}`)
  }

  return data.data
}

/**
 * List get files for brand
 *
 * @param number userId
 * @param string authToken
 * @param number brandId
 *
 * @return object
 */
const $companyMSGetFilesBrand = async ({ userId, authToken, brandId }) => {
  const { data } = await axios.get(
    `${BASE_URL}/public/companies/${brandId}/files`,
    {
      headers: {
        Authorization: `${authToken}`,
        'User-Id': `${userId}`,
      },
    }
  )

  return data
}

/**
 * Update Company
 *
 * @param number userId
 * @param string authToken
 * @param number brandId
 *
 * @return object
 */
const $companyMSDestroyBrand = async ({ userId, authToken, brandId }) => {
  const { data } = await axios.delete(
    `${BASE_URL}/public/companies/brands/${brandId}`,
    {
      headers: {
        Authorization: `${authToken}`,
        'User-Id': `${userId}`,
      },
    }
  )

  return data
}

/**
 * Update Company
 *
 * @param number userId
 * @param string authToken
 * @param number brandId
 * @param object payload
 *
 * @return object
 */
const $companyMSUpdateBrand = async ({
  userId,
  authToken,
  brandId,
  payload,
}) => {
  const { data } = await axios.patch(
    `${BASE_URL}/public/companies/brands/${brandId}`,
    payload,
    {
      headers: {
        Authorization: `${authToken}`,
        'User-Id': `${userId}`,
      },
    }
  )

  return data
}

/**
 * show Company
 *
 * @param number userId
 * @param string authToken
 * @param number companyId
 *
 * @return object
 */
const $companyMSShow = async ({ userId, authToken, companyId }) => {
  const { data } = await axios.get(`${BASE_URL}/companies/${companyId}`, {
    headers: {
      Authorization: `${authToken}`,
      'User-Id': `${userId}`,
    },
  })

  return data
}

/**
 * get all companies
 *
 * @param number userId
 * @param string authToken
 *
 * @return object
 */
const $companyMSGetAll = async ({ userId, authToken }) => {
  const { data } = await axios.get(`${BASE_URL}/companies`, {
    headers: {
      Authorization: `${authToken}`,
      'User-Id': `${userId}`,
    },
  })

  return data
}

/**
 * creates brand
 *
 * @param number userId
 * @param string authToken
 * @param object payload
 *
 * @return object
 */
const $companyMSCreateBrand = async ({ userId, authToken, payload }) => {
  const { data } = await axios.post(
    `${BASE_URL}/public/companies/brands`,
    payload,
    {
      headers: {
        Authorization: `${authToken}`,
        'User-Id': `${userId}`,
      },
    }
  )

  return data
}

export {
  $companyMSTicketCountByCompany,
  $companyMSTags,
  $companyMSDeleteTags,
  $companyMSPostTags,
  $companyMSUpdateCompany,
  $companyMSCreateCompany,
  $companyMSListIndustries,
  $companyMSShowBrand,
  $companyMSUsersOfCompany,
  $companyMSUpdateBrandAssets,
  $companyMSDestroyBrandAssets,
  $companyMSListBrands,
  $companyMSGetFilesBrand,
  $companyMSUpdateBrand,
  $companyMSDestroyBrand,
  $companyMSShow,
  $companyMSGetAll,
  $companyMSCreateBrand,
}
