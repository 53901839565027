import { render, staticRenderFns } from "./kanban.vue?vue&type=template&id=6a73cf30&scoped=true&"
import script from "./kanban.vue?vue&type=script&lang=js&"
export * from "./kanban.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a73cf30",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Notifications: require('/codebuild/output/src985175027/src/nuxt-thefutures/components/notifications/Index.vue').default,ProfileImage: require('/codebuild/output/src985175027/src/nuxt-thefutures/components/ProfileImage.vue').default,Toast: require('/codebuild/output/src985175027/src/nuxt-thefutures/components/Toast.vue').default,ToastNotification: require('/codebuild/output/src985175027/src/nuxt-thefutures/components/ToastNotification.vue').default})
