/***
 * updates ticket's attended field,
 * plLastSeen and plLastSeenAt
 *
 * marks all ticket's notifications a read
 */

import { format } from 'date-fns'

// eslint-disable-next-line require-await
export default async function (context) {
  const user = context.$auth.$storage.getState('user')
  const { id } = context.route.params
  const userId = user.sub
  const payload = {
    userId,
    authToken: context.$auth.$storage.getUniversal('_token.cookie'),
    ticketId: id,
  }

  try {
    const { success, message } = await context.$notificationMS.markRead(payload)

    if (!success) {
      throw new Error(
        `Error marking tickets' notifications as read: ${message}`
      )
    }
  } catch (error) {
    context.$sentry.setContext('user', user)
    context.$sentry.setTag('context', 'attend notification middleware')
    context.$sentry.captureException(error)
  }

  if (user.roles.includes('is-pl') || user.roles.includes('admin')) {
    const payload = {
      userId,
      authToken: context.$auth.$storage.getUniversal('_token.cookie'),
      id,
      payload: {
        plLastSeen: user.sub,
        plLastSeenAt: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        attended: 1,
      },
    }

    try {
      const { success, message } = await context.$ticketMS.put(payload)

      if (!success) {
        throw new Error(
          `Error updating ticket plLastSeen & plLastSeenAt: ${message}`
        )
      }
    } catch (error) {
      context.$sentry.setContext('user', user)
      context.$sentry.setTag('context', 'attend notification middleware')
      context.$sentry.captureException(error)
    }
  }
}
