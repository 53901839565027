
import { mapState, mapGetters, mapActions } from 'vuex'
import { isEmpty } from 'lodash'

export default {
  components: {
    Notifications: () => import('~/components/notifications/Index.vue'),
  },

  filters: {
    hideMonth(subscriptionName) {
      const newSubName = subscriptionName.split(' - Jul20')
      return newSubName[0]
    },
  },

  data() {
    return {
      windowSize: {
        x: 0,
        y: 0,
      },
    }
  },

  computed: {
    ...mapState('dashboardState', {
      loading: 'loading',
    }),
    ...mapGetters('account', {
      subscriptions: 'subscriptions',
    }),

    payload() {
      const authUser = this.$auth.$storage.getState('user')

      return {
        userId: authUser.sub,
        authToken: this.$auth.$storage.getUniversal('_token.cookie'),
      }
    },

    userName() {
      const authUser = this.$auth.$storage.getState('user')

      return authUser ? authUser.name.split(' ')[0] : ''
    },
    userImage() {
      const authUser = this.$auth.$storage.getState('user')

      return authUser ? authUser.image : ''
    },
    user() {
      return this.$auth.$storage.getState('user')
    },
    isDesigner() {
      if (!this.user) {
        return false
      }

      return (
        this.user.roles.includes('designer') &&
        (!this.user.roles.includes('design-leader') ||
          !this.user.roles.includes('is-admin'))
      )
    },
    isDesignLeader() {
      if (!this.user) {
        return false
      }

      return (
        this.user.roles.includes('design-leader') &&
        (!this.user.roles.includes('designer') ||
          !this.user.roles.includes('is-admin'))
      )
    },
    toolsItems() {
      if (this.isDesigner) {
        return [
          {
            name: 'Kanban',
            isExternal: false,
            route: '/kanban',
            icon: 'fab fa-trello',
          },
          {
            name: 'Employee Tools',
            isExternal: true,
            route: 'https://intra.thefutures.io/',
            icon: 'fa fa-calendar-check',
          },
        ]
      }

      if (this.isDesignLeader) {
        return [
          {
            name: 'Kanban',
            isExternal: false,
            route: '/kanban',
            icon: 'fab fa-trello',
          },
          // {
          //   name: 'Create User',
          //   isExternal: false,
          //   route: '/users/create',
          //   icon: 'fas fa-user',
          // },
          {
            name: 'Client List',
            isExternal: false,
            route: '/cx/clients',
            icon: 'fas fa-list-alt',
          },
          {
            name: 'Employee Tools',
            isExternal: true,
            route: 'https://intra.thefutures.io/',
            icon: 'fa fa-calendar-check',
          },
          {
            name: 'Client Panel',
            isExternal: false,
            route: '/tickets',
            icon: 'fa fa-columns',
          },
          // {
          //   name: 'View As',
          //   isExternal: false,
          //   route: '/view-as',
          //   icon: 'fas fa-eye',
          // },
        ]
      }

      // is admin
      return [
        {
          name: 'Kanban',
          isExternal: false,
          route: '/kanban',
          icon: 'fab fa-trello',
        },
        {
          name: 'Create User',
          isExternal: false,
          route: '/users/create',
          icon: 'fas fa-user',
        },
        {
          name: 'Client List',
          isExternal: false,
          route: '/cx/clients',
          icon: 'fas fa-list-alt',
        },
        {
          name: 'Employee Tools',
          isExternal: true,
          route: 'https://intra.thefutures.io/',
          icon: 'fa fa-calendar-check',
        },
        {
          name: 'Client Panel',
          isExternal: false,
          route: '/tickets',
          icon: 'fa fa-columns',
        },
        {
          name: 'View As',
          isExternal: false,
          route: '/view-as',
          icon: 'fas fa-eye',
        },
      ]
    },

    companyName() {
      if (this.currentUser) {
        if (this.currentUser.company) return this.currentUser.company.name
      }

      return false
    },
  },

  async mounted() {
    // this.$vuetify.theme.dark = false
    this.onResize()
    // await this.getUserAccount({ payload: this.payload })
    if (isEmpty(this.$store.state.account.currentUser)) {
      // console.log('fetching account getDataUser')
      await this.getDataUser({ payload: this.payload })
    }
  },

  methods: {
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },

    ...mapActions('account', {
      getUserAccount: 'getUserAccount',
      getDataUser: 'getDataUser',
    }),
  },
}
