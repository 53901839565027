export const state = () => ({
  user: false,
})

export const getters = {
  getUser: (state) => state.user,
}

export const mutations = {
  setData(state, payload) {
    payload.forEach((element) => {
      state[element.property] = element.data
    })
  },
}

export const actions = {
  async fetchUser({ state, commit }, user) {
    const authUser = this.$auth.$storage.getState('user')
    const payload = {
      // for auth
      userId: authUser.sub,
      authToken: this.$auth.$storage.getUniversal('_token.cookie'),
      // actual userId being fetched
      user,
    }

    const response = await this.$userMS.show(payload)

    commit('setData', [{ property: 'user', data: response }])

    return response
  },
}
