import { $companyMSShow } from '@/MS/company'

export default async function (context) {
  const authToken = context.$auth.$storage.getUniversal('_token.cookie')
  const user = context.$auth.$storage.getState('user')
  if (!user.companyId) return context.redirect('/')

  const payload = {
    userId: user.sub,
    authToken,
    companyId: user.companyId,
  }

  const response = await $companyMSShow(payload)
  if (!response.success) return context.redirect('/')
  const { onboard } = response.data
  const path = context.route.path
  switch (onboard) {
    case null:
      if (path !== '/onboarding/step1') {
        return context.redirect('/onboarding/step1')
      }
      break
    case 0:
      if (path !== '/onboarding/step1') {
        return context.redirect('/onboarding/step1')
      }
      break
    case 1:
      if (path !== '/onboarding/step2') {
        return context.redirect('/onboarding/step2')
      }
      break
    case 2:
      if (path !== '/onboarding/step3') {
        return context.redirect('/onboarding/step3')
      }
      break
    case 3:
      return context.redirect('/tickets')
  }
}
