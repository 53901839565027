export default ({ app, $axios, $config }, inject) => {
  async function user({ userId, authToken }) {
    return await $axios.$get(`${$config.baseURL}/public/users/${userId}`, {
      headers: {
        Authorization: `${authToken}`,
      },
    })
  }

  async function show({ userId, authToken }) {
    return await $axios.$get(
      `${$config.baseURL}/public/account/${userId}/thefutures`,
      {
        headers: {
          Authorization: `${authToken}`,
        },
      }
    )
  }

  async function trialing({ userId, authToken }) {
    const response = await $axios.$get(
      `${$config.baseURL}/public/account/${userId}/thefutures/subscriptions-trialing`,

      {
        headers: {
          Authorization: `${authToken}`,
        },
      }
    )

    return response
  }

  async function description({ userId, authToken }) {
    const response = await $axios.$get(
      `${$config.baseURL}/public/account/${userId}/thefutures/description?language=en`,

      {
        headers: {
          Authorization: `${authToken}`,
        },
      }
    )

    return response
  }

  async function cancel({ userId, authToken, stripeId, cancelNow }) {
    const response = await $axios.$post(
      `${$config.baseURL}/public/account/${userId}/thefutures/cancel/${stripeId}`,
      {
        cancel_now: cancelNow,
      },

      {
        headers: {
          Authorization: `${authToken}`,
        },
      }
    )

    return response
  }

  async function pause({ userId, authToken, stripeId }) {
    const response = await $axios.$post(
      `${$config.baseURL}/public/account/${userId}/thefutures/pause-v2`,
      {
        subscriptions: [stripeId],
      },

      {
        headers: {
          Authorization: `${authToken}`,
        },
      }
    )

    return response
  }

  async function unpause({ userId, authToken, stripeId }) {
    const response = await $axios.$put(
      `${$config.baseURL}/public/account/${userId}/thefutures/unpause`,
      {
        subscriptions: [stripeId],
      },

      {
        headers: {
          Authorization: `${authToken}`,
        },
      }
    )

    return response
  }

  async function apiKey({ companyId, authToken }) {
    const response = await $axios.$get(
      `${$config.baseURL}/companies/${companyId}/api-key`,

      {
        headers: {
          Authorization: `${authToken}`,
        },
      }
    )

    return response
  }

  async function retriveApiKey({ companyId, authToken }) {
    const response = await $axios.$post(
      `${$config.baseURL}/companies/api-key`,
      {
        companyId,
      },

      {
        headers: {
          Authorization: `${authToken}`,
        },
      }
    )

    return response
  }

  async function updateEmail({ userId, authToken, email }) {
    const response = await $axios.$put(
      `${$config.baseURL}/public/account/${userId}/thefutures/update-email`,
      {
        email,
      },

      {
        headers: {
          Authorization: `${authToken}`,
        },
      }
    )

    return response
  }

  async function updatePersonalAndDisabled({ userId, authToken, data }) {
    const response = await $axios.$patch(
      `${$config.baseURL}/public/users/${userId}`,
      data,

      {
        headers: {
          Authorization: `${authToken}`,
        },
      }
    )

    return response
  }

  async function updateBizDetails({ companyId, authToken, data }) {
    const response = await $axios.$patch(
      `${$config.baseURL}/companies/${companyId}`,
      data,

      {
        headers: {
          Authorization: `${authToken}`,
        },
      }
    )

    return response
  }

  async function getProductFeature({ userId, authToken, featureCode }) {
    const response = await $axios.$get(
      `${$config.baseURL}/public/features/productfeature/${featureCode}`,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )

    if (!response.success) {
      throw new Error(`Error getting product features for ${featureCode}`)
    }

    return response.data
  }

  async function createAccount({ userId, authToken, payload }) {
    const response = await $axios.$post(
      `${$config.baseURL}/public/account/${userId}/thefutures/register`,
      payload,
      {
        headers: {
          Authorization: `${authToken}`,
        },
      }
    )

    return response
  }

  async function getplan({ planCode, authToken }) {
    const response = await $axios.$get(
      `${$config.baseURL}/public/plans/${planCode}`,
      {
        headers: {
          Authorization: `${authToken}`,
        },
      }
    )

    return response
  }

  /**
   * Implements ACCOUNT PUT from Laravel's AccountMSHelper
   *
   * userId & authToken serve for validation
   *
   * payload is an object with the updates to made to the user account.
   *
   * Errors in response should be handled in the method calling this function
   */
  async function updateAccount({ userId, authToken, payload }) {
    return await $axios.$put(
      `${$config.baseURL}/public/account/${userId}/thefutures`,
      payload,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  /**
   * mocks Laravel's AccountMSHelper::OneOffStorePaymentMethod
   *
   * @param object user
   * @param object payload (to be passed to updateAccount)
   * @param string paymentMehtod (optional)
   *
   * @return undefined
   */
  async function oneOffStorePaymentMethod(
    user,
    payload,
    paymentMethod = false
  ) {
    const data = {}

    if (!user.stripeId || user.stripeId === null) {
      if (!paymentMethod) {
        throw new Error('Payment Method is not defined')
      }

      data.parent_user_id = user.invitatorId
      data.payment_method_id = paymentMethod
      data.first_name = user.name
      data.last_name = user.lastname
      data.email = user.email
    } else {
      data.parent_user_id = user.invitatorId
      data.stripe_customer_id = user.stripeId
      data.first_name = user.name
      data.last_name = user.lastname
      data.email = user.email
    }

    const payloadToUpdate = {
      userId: payload.userId,
      authToken: payload.authToken,
      payload: data,
    }

    const response = await updateAccount(payloadToUpdate)

    if (!response.success) {
      throw new Error(
        `Error upading account for user ${payload.userId}: ${
          response.message ? response.message : ''
        }`
      )
    }
  }

  /**
   * mocks Laravel's AccountMSHelper::updateCustomerInvoiceData
   *
   * @param int userId
   * @param string authToken
   * @param object payload
   *
   * @return undefined
   */
  async function updateCustomerInvoiceData({ userId, authToken, payload }) {
    return await $axios.$post(
      `${$config.baseURL}/public/account/v1/stripe/${userId}/thefutures/updatecustomerdetails`,
      payload,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  /**
   * mocks Laravel's AccountMSHelper::OneOffCharge
   *
   * @param int userId
   * @param string authToken
   * @param object payload
   *
   * @return object
   */
  async function oneOffCharge({ userId, authToken, payload }) {
    return await $axios.$put(
      `${$config.baseURL}/public/account/${userId}/thefutures/purchased?charge=true`,
      payload,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  /**
   * endpoint to consume a feature
   *
   * @param int userId
   * @param string authToken
   * @param object payload
   *
   * @return object
   */
  async function consume({ userId, authToken, payload }) {
    return await $axios.$put(
      `${$config.baseURL}/public/account/${userId}/thefutures/consume`,
      payload,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  async function subscribe({ userId, authToken, payload }) {
    // {
    //   "plan_id": "price_1IRD8AFUC9zuNGMeWmh0EQM7",
    //   "stripe_coupon": "STARTUP80",
    //     "subscription_type": "software",
    //     "quantity":2
    // }
    const response = await $axios.$post(
      `${$config.baseURL}/public/account/${userId}/thefutures/subscribe`,
      payload,
      {
        headers: {
          Authorization: `${authToken}`,
        },
      }
    )

    return response
  }
  async function validCoupon({ authToken, payload }) {
    const response = await $axios.$post(
      `${$config.baseURL}/public/coupons/valid/name`,
      payload,

      {
        headers: {
          Authorization: `${authToken}`,
        },
      }
    )

    return response
  }
  async function coupon({ authToken, payload }) {
    const response = await $axios.$post(
      `${$config.baseURL}/public/coupons/name`,
      payload,

      {
        headers: {
          Authorization: `${authToken}`,
        },
      }
    )

    return response
  }
  async function welcome({ authToken, userId }) {
    const response = await $axios.$post(
      `${$config.baseURL}/public/users/welcome/${userId}`,
      {
        headers: {
          Authorization: `${authToken}`,
        },
      }
    )
    return response
  }
  async function discount({ authToken, userId }) {
    const response = await $axios.$post(
      `${$config.baseURL}/public/account/discount`,
      {},
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
    return response
  }
  async function reasons({ authToken, userId, payload }) {
    const response = await $axios.$post(
      `${$config.baseURL}/public/account/cancel`,
      payload,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
    return response
  }

  async function getCustomerInvoices({ authToken, payload }) {
    return await $axios.post(
      `${$config.baseURL}/public/account/invoices`,
      payload,
      {
        headers: {
          Authorization: `${authToken}`,
        },
      }
    )
  }

  async function getCustomerInvoiceDetail({ authToken, invoice }) {
    return await $axios.get(
      `${$config.baseURL}/public/account/invoice/detail/${invoice}`,
      {
        headers: {
          Authorization: `${authToken}`,
        },
      }
    )
  }
  async function getSubscriptionUser({ userId, authToken }) {
    return await $axios.$get(
      `${$config.baseURL}/public/account/${userId}/thefutures/subscriptions-user`,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  const accountMS = {
    user,
    show,
    trialing,
    description,
    cancel,
    pause,
    unpause,
    apiKey,
    retriveApiKey,
    updateEmail,
    updatePersonalAndDisabled,
    updateBizDetails,
    getProductFeature,
    createAccount,
    getplan,
    updateAccount,
    oneOffStorePaymentMethod,
    updateCustomerInvoiceData,
    oneOffCharge,
    consume,
    subscribe,
    validCoupon,
    coupon,
    welcome,
    discount,
    reasons,
    getCustomerInvoices,
    getCustomerInvoiceDetail,
    getSubscriptionUser,
  }

  inject('accountMS', accountMS)
}
