
import { mapState } from 'vuex'
export default {
  name: 'SupportLayout',
  data() {
    return {
      windowSize: {
        x: 0,
        y: 0,
      },
    }
  },
  computed: {
    ...mapState('dashboardState', {
      loading: 'loading',
    }),
    userName() {
      const authUser = this.$auth.$storage.getState('user')

      return authUser ? authUser.name.split(' ')[0] : ''
    },
    userImage() {
      const authUser = this.$auth.$storage.getState('user')

      return authUser ? authUser.image : ''
    },
  },
  methods: {
    goBack() {
      this.$router.push(`/tickets`)
    },
  },
}
