export const state = () => ({
  loading: false,
})

export const getters = {
  getCounter(state) {
    return state.counter
  },
}

export const mutations = {
  setData(state, payload) {
    payload.forEach((element) => {
      state[element.property] = element.data
    })
  },
}

// export const actions = {
//   fetchCounter({ state }) {
//     // make request
//     const res = { data: 10 }
//     state.counter = res.data
//     return res.data
//   },
// }
