// eslint-disable-next-line import/no-absolute-path
// import mostUsedFeaturesJSON from '/exampleJSONResponses/mostUsedFeatures.json'

export default ({ app, $axios, $config }, inject) => {
  async function mostUsedFeatures({ userId, authToken }) {
    const response = await $axios.$get(
      `${$config.baseURL}/public/clients/${userId}/features/mostused`,
      {
        headers: {
          Authorization: `${authToken}`,
        },
      }
    )

    return response
  }

  async function featuresUsed({ companyId, authToken }) {
    const response = await $axios.$get(
      `${$config.baseURL}/companies/${companyId}/features/used`,
      {
        headers: {
          Authorization: `${authToken}`,
        },
      }
    )

    return response
  }

  async function list({ userId, authToken, filter = '' }) {
    return await $axios.$get(`${$config.baseURL}/public/tickets?${filter}`, {
      headers: {
        Authorization: `${authToken}`,
        'User-Id': `${userId}`,
      },
    })
  }

  async function show({ userId, authToken, ticketId }) {
    return await $axios.$get(`${$config.baseURL}/public/tickets/${ticketId}`, {
      headers: {
        Authorization: `${authToken}`,
        'User-Id': `${userId}`,
      },
    })
  }

  async function post({ userId, authToken, payload, url = '' }) {
    return await $axios.$post(
      `${$config.baseURL}/public/tickets/${url}`,
      payload,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  async function put({ userId, authToken, payload, id }) {
    return await $axios.$put(
      `${$config.baseURL}/public/tickets/${id}`,
      payload,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  async function changeStatusCustom({ userId, authToken, payload, id }) {
    return await $axios.$patch(
      `${$config.baseURL}/public/tickets/${id}`,
      {
        status: payload,
      },
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  /**
   * endpoint to expedite a ticket
   *
   * @param int userId
   * @param string authToken
   * @param int ticketId
   * @param object payload
   *
   * @return object
   */
  async function expedite({ userId, authToken, ticketId, payload }) {
    return await $axios.$post(
      `${$config.baseURL}/public/tickets/${ticketId}/actions/expedite`,
      payload,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  /**
   * endpoint to get submissions
   *
   * @param string query used for specify view
   */
  async function getDesignersSubmissions({
    userId,
    authToken,
    ticketId,
    query = '',
  }) {
    const response = await $axios.$get(
      `${$config.baseURL}/public/submissions?ticket=${ticketId}${query}`,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )

    return response
  }

  async function listCopies({ userId, authToken, ticketId }) {
    return await $axios.$get(
      `${$config.baseURL}/public/tickets/${ticketId}/copies`,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  async function storeCopy({ userId, authToken, ticketId, payload }) {
    return await $axios.$post(
      `${$config.baseURL}/public/tickets/${ticketId}/copies`,
      payload,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  async function deleteCopy({ userId, authToken, ticketId, copyId }) {
    return await $axios.$delete(
      `${$config.baseURL}/public/tickets/${ticketId}/copies/${copyId}`,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  async function destroy({ userId, authToken, ticketId }) {
    return await $axios.$delete(
      `${$config.baseURL}/public/tickets/${ticketId}`,
      {
        headers: {
          Authorization: authToken,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  async function pause({ userId, authToken, ticketId }) {
    return await $axios.$post(
      `${$config.baseURL}/public/tickets/${ticketId}/actions/pause`,
      {},
      {
        headers: {
          Authorization: authToken,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  async function approve({ userId, authToken, ticketId, payload }) {
    return await $axios.$post(
      `${$config.baseURL}/public/tickets/${ticketId}/actions/close`,
      payload,
      {
        headers: {
          Authorization: authToken,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  async function resume({ userId, authToken, ticketId }) {
    return await $axios.$post(
      `${$config.baseURL}/public/tickets/${ticketId}/actions/queue`,
      {},
      {
        headers: {
          Authorization: authToken,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  async function reorder({ authToken, payload }) {
    return await $axios.$patch(
      `${$config.baseURL}/public/tickets/reorder/array`,
      payload,
      {
        headers: {
          Authorization: authToken,
        },
      }
    )
  }

  async function sort({ authToken, payload }) {
    return await $axios.$patch(
      `${$config.baseURL}/public/tickets/sort/array`,
      payload,
      {
        headers: {
          Authorization: authToken,
        },
      }
    )
  }

  async function tagging({ authToken, payload }) {
    return await $axios.$post(`${$config.baseURL}/public/taggings`, payload, {
      headers: {
        Authorization: authToken,
      },
    })
  }

  async function deleteTagging({ authToken, taggingId }) {
    return await $axios.$delete(
      `${$config.baseURL}/public/taggings/${taggingId}`,
      {
        headers: {
          Authorization: authToken,
        },
      }
    )
  }
  async function getTicketsBrand({ userId, authToken, brandId }) {
    return await $axios.$get(
      `${$config.baseURL}/public/tickets/brand/${brandId}`,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  async function getTicketStatisticsByCompany({ authToken, companyId }) {
    return await $axios.$get(
      `${$config.baseURL}/public/tickets/statistics/companies/${companyId}`,
      {
        headers: {
          Authorization: authToken,
        },
      }
    )
  }

  async function listCannedResponses({ userId, authToken }) {
    return await $axios.$get(`${$config.baseURL}/public/cannedresponses`, {
      headers: {
        Authorization: `${authToken}`,
        'User-Id': `${userId}`,
      },
    })
  }

  async function deleteCannedResponse({ userId, authToken, id }) {
    return await $axios.$delete(
      `${$config.baseURL}/public/cannedresponses/${id}`,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  async function createCannedResponse({ userId, authToken, payload }) {
    return await $axios.$post(
      `${$config.baseURL}/public/cannedresponses`,
      payload,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  async function updateCannedResponse({ userId, authToken, payload, id }) {
    return await $axios.$put(
      `${$config.baseURL}/public/cannedresponses/${id}`,
      payload,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  async function getNotes({ userId, authToken, query = '' }) {
    return await $axios.$get(`${$config.baseURL}/public/notes?${query}`, {
      headers: {
        Authorization: `${authToken}`,
        'User-Id': `${userId}`,
      },
    })
  }

  async function createNote({ userId, authToken, payload }) {
    return await $axios.$post(`${$config.baseURL}/public/notes`, payload, {
      headers: {
        Authorization: `${authToken}`,
        'User-Id': `${userId}`,
      },
    })
  }

  async function getDraftNote({ userId, authToken, query = '' }) {
    return await $axios.$get(
      `${$config.baseURL}/public/notes/last/draft?${query}`,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  async function updateNote({ userId, authToken, id, payload }) {
    return await $axios.$put(`${$config.baseURL}/public/notes/${id}`, payload, {
      headers: {
        Authorization: `${authToken}`,
        'User-Id': `${userId}`,
      },
    })
  }

  async function deleteNote({ userId, authToken, id }) {
    return await $axios.$delete(`${$config.baseURL}/public/notes/${id}`, {
      headers: {
        Authorization: `${authToken}`,
        'User-Id': `${userId}`,
      },
    })
  }

  async function storeComment({ userId, authToken, ticketId, payload }) {
    return await $axios.$post(
      `${$config.baseURL}/public/tickets/${ticketId}/comments`,
      payload,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  async function deleteComment({ userId, authToken, id }) {
    return await $axios.$delete(
      `${$config.baseURL}/public/tickets/comments/${id.id}`,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  async function listTasks({ userId, authToken, query = '' }) {
    return await $axios.$get(`${$config.baseURL}/public/tasks?${query}`, {
      headers: {
        Authorization: `${authToken}`,
        'User-Id': `${userId}`,
      },
    })
  }

  async function storeTask({ userId, authToken, payload }) {
    return await $axios.$post(`${$config.baseURL}/public/tasks`, payload, {
      headers: {
        Authorization: `${authToken}`,
        'User-Id': `${userId}`,
      },
    })
  }

  async function updateTask({ userId, authToken, payload, id }) {
    return await $axios.$put(`${$config.baseURL}/public/tasks/${id}`, payload, {
      headers: {
        Authorization: `${authToken}`,
        'User-Id': `${userId}`,
      },
    })
  }

  async function deleteTask({ userId, authToken, id }) {
    return await $axios.$patch(
      `${$config.baseURL}/public/tasks/${id}`,
      {},
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  async function addLoomLink({ userId, authToken, payload, id }) {
    return await $axios.$post(
      `${$config.baseURL}/public/tickets/${id}/links`,
      payload,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  const ticketMS = {
    deleteComment,
    mostUsedFeatures,
    featuresUsed,
    list,
    show,
    post,
    put,
    changeStatusCustom,
    expedite,
    getDesignersSubmissions,
    listCopies,
    storeCopy,
    deleteCopy,
    destroy,
    pause,
    resume,
    reorder,
    sort,
    tagging,
    deleteTagging,
    getTicketsBrand,
    getTicketStatisticsByCompany,
    approve,
    listCannedResponses,
    deleteCannedResponse,
    createCannedResponse,
    updateCannedResponse,
    getNotes,
    getDraftNote,
    updateNote,
    deleteNote,
    createNote,
    storeComment,
    listTasks,
    storeTask,
    updateTask,
    deleteTask,
    addLoomLink,
  }

  inject('ticketMS', ticketMS)
}
