export default ({ app, $axios, $config }, inject) => {
  async function show({ userId, authToken, user }) {
    const response = await $axios.$get(
      `${$config.baseURL}/public/users/${user}`,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )

    if (!response.success) {
      throw new Error(`Error getting user data for ${user}`)
    }

    return response.data
  }

  async function autoManagement({ userId, authToken, payload }) {
    return await $axios.$patch(
      `${$config.baseURL}/public/users/${userId}/auto-management`,
      payload,
      {
        headers: {
          Authorization: authToken,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  async function list({ userId, authToken, query = '' }) {
    return await $axios.$get(`${$config.baseURL}/public/users?${query}`, {
      headers: {
        Authorization: `${authToken}`,
        'User-Id': `${userId}`,
      },
    })
  }

  async function update({ userId, authToken, payload }) {
    const response = await $axios.$patch(
      `${$config.baseURL}/public/users/${userId}`,
      payload,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )

    if (!response.success) {
      throw new Error(`Error getting user data for ${userId}`)
    }

    return response.data
  }

  async function getAll({ userId, authToken }) {
    const response = await $axios.$get(`${$config.baseURL}/public/users/`, {
      headers: {
        Authorization: `${authToken}`,
        'User-Id': `${userId}`,
      },
    })

    if (!response.success) {
      throw new Error(`Error getting user data for ${userId}`)
    }

    return response.data
  }

  async function updateEmployee({ userId, authToken, payload }) {
    const response = await $axios.$put(
      `${$config.baseURL}/public/employees/${userId}`,
      payload,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )

    if (!response.success) {
      throw new Error(`Error getting user data for ${userId}`)
    }

    return response.data
  }
  async function getInvitations({ userId, authToken, query }) {
    return await $axios.$get(
      `${$config.baseURL}/public/v1/invitations${query}`,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  async function getInvitationByToken({ token }) {
    return await $axios.$get(
      `${$config.baseURL}/public/v1/invitations/${token}`
    )
  }
  async function postInvitation({ userId, authToken, payload }) {
    return await $axios.$post(
      `${$config.baseURL}/public/v1/invitations`,
      payload,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }
  async function deleteInvitation({ userId, authToken, invitationId }) {
    return await $axios.$delete(
      `${$config.baseURL}/public/v1/invitations/${invitationId}`,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }
  async function deleteUser({ userId, authToken, userInvitedId }) {
    return await $axios.$delete(
      `${$config.baseURL}/public/users/${userInvitedId}`,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  async function auth({ userId, authToken, loginUserId }) {
    return await $axios.$post(
      `${$config.baseURL}/public/auth/${loginUserId}`,
      {},
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  async function listRecommendedDesigners({ userId, authToken, query = '' }) {
    return await $axios.$get(
      `${$config.baseURL}/hr/designers/recommended?${query}`,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  async function listClients({ userId, authToken, offset, keyword = false }) {
    return await $axios.$get(
      `${$config.baseURL}/public/clients?offset=${offset}${
        keyword ? '&keyword=' + keyword : ''
      }`,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  async function getStatistics({ userId, authToken, query }) {
    return await $axios.$get(
      `${$config.baseURL}/public/users/active/stats?${query}`,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  async function sendResetPassword(payload) {
    return await $axios.$post(
      `${$config.baseURL}/public/users/reset/password`,
      payload
    )
  }

  async function resetPassword({ query, payload }) {
    return await $axios.$post(
      `${$config.baseURL}/public/users/update/password?rememberToken=${query}`,
      payload
    )
  }

  async function uploadProfilePhoto({ userId, authToken, payload }) {
    return await $axios.$post(
      `${$config.baseURL}/public/users/change/avatar`,
      payload,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  const userMS = {
    show,
    list,
    getAll,
    update,
    updateEmployee,
    autoManagement,
    listRecommendedDesigners,
    getInvitations,
    postInvitation,
    deleteInvitation,
    deleteUser,
    auth,
    sendResetPassword,
    resetPassword,
    listClients,
    getStatistics,
    uploadProfilePhoto,
    getInvitationByToken,
  }

  inject('userMS', userMS)
}
