/* eslint-disable no-console */
/* eslint-disable camelcase */
import axios from 'axios'
import { set } from 'vue'
import { fillColumns, setTicketTasks } from '~/utils/itemHelper'
import { setCookie, getCookie, eraseCookie } from '~/utils/kanbanConfig'
import columnsJson from '~/utils/columns.json'

export const state = () => ({
  isLoading: true,
  ticketsDueby: ['waiting-for-approval', 'being-checked', 'under-way'],
  base_columns: [],
  columns: [],
  tickets: [],
  tasks: [],
  activeTask: {},
  authUser: {},
  designers: [],
  moveable: true,
  beingChecked: false,
  weHaveQuestions: false,
  searcherText: '',
  filter_data: {
    designer: {},
    due_date: '',
    type_of_item: '',
    filtered: false,
  },
  showAddTaskModal: false,
  showTaskListModal: false,
  showTaskDetailsModal: false,
  showFiltersModal: false,
  showRecommendedModal: false,
})

export const getters = {
  isLoading: (state) => state.isLoading,
  activeTask: (state) => state.activeTask,
  getColumsArray: (state) => Object.values(state.columns),
  getAuthUser: (state) => state.authUser,
  getDesigners: (state) => state.designers,
  getFilterData: (state) => state.filter_data,
  getBaseColumns: (state) => state.base_columns,
  getMoveable: (state) => state.moveable,
  getbeingChecked: (state) => state.beingChecked,
  getweHaveQuestions: (state) => state.weHaveQuestions,
}

export const mutations = {
  setData(state, payload) {
    payload.forEach((element) => {
      state[element.property] = element.data
    })
  },

  SET_AUTH_USER(state, payload) {
    state.authUser = payload.authUser
  },
  SET_DESIGNERS(state, payload) {
    state.designers = payload.designers
  },
  SET_LOADING_STATE(state, payload) {
    state.isLoading = payload
  },
  LOAD_BASE_COLUMNS(state) {
    const base_columns = JSON.parse(getCookie('base_columns'))
    if (base_columns) {
      state.base_columns = base_columns
    } else {
      const { columns } = columnsJson
      state.base_columns = columns
    }
  },
  FILL_COLUMNS(state) {
    const new_tickets = [...state.tickets]
    const new_tasks = [...state.tasks]
    const searcherText = state.searcherText
    const filter_data = state.filter_data
    const base_columns = state.base_columns
    state.columns = fillColumns(
      base_columns,
      new_tickets,
      new_tasks,
      filter_data,
      searcherText,
      state.authUser
    )
  },
  CHANGE_FILTER_STATUS(state, status) {
    state.filter_data.filtered = status
  },
  SET_MOVEABLE(state, status) {
    state.moveable = status
  },
  SET_BEGIN_CHECKED(state, status) {
    state.beingChecked = status
  },
  SET_WE_HAVE_QUESTION(state, status) {
    state.weHaveQuestions = status
  },
  CHANGE_COLUMN_STATUS(state, payload) {
    const idx = state.columns.findIndex(
      (column) => column.name === payload.column_name
    )
    state.columns[idx].active = !state.columns[idx].active
    state.base_columns[idx].active = !state.base_columns[idx].active

    const base_columns_string = JSON.stringify(state.base_columns)
    setCookie('base_columns', base_columns_string, 30)
  },

  REFRESH_DATA_SAME_COLUMN(state, payload) {
    const columns = state.columns
    const tickets_dueby = state.tickets_dueby
    const listIdx = columns.findIndex((list) => list.code === payload.list.code)
    if (tickets_dueby.includes(payload.list.code)) {
      columns[listIdx].items.sort((a, b) => {
        return new Date(a.dueBy) - new Date(b.dueBy)
      })
    } else {
      columns[listIdx].items.sort((a, b) => {
        return b.ticketId - a.ticketId
      })
    }
  },
  REFRESH_DATA_OTHER_COLUMN(state, payload) {
    const tickets_dueby = state.tickets_dueby
    const columns = state.columns
    const listIdx = columns.findIndex((list) => list.code === payload.list.code)
    const itemIdx = columns[listIdx].items.findIndex(
      (list) => list.code === payload.list.code
    )

    columns[listIdx].items[itemIdx] = payload.item
    if (tickets_dueby.includes(payload.list.code)) {
      columns[listIdx].items.sort((a, b) => {
        return new Date(a.dueBy) - new Date(b.dueBy)
      })
    } else {
      columns[listIdx].items.sort((a, b) => {
        return b.ticketId - a.ticketId
      })
    }
  },
  REORDER_TASKLIST_ITEMS_BOARD(state, payload) {
    const columns = state.columns
    const listIdx = columns.findIndex((l) => l.code === payload.listCode)
    set(columns[listIdx], 'items', payload.items)
  },
  ADD_TASK_TO_COLUMN(state, payload) {
    const new_tasks = payload.new_tasks.map((task) => {
      task.isTask = true
      return task
    })
    let tasks = state.tasks
    tasks = tasks.concat(new_tasks)
    tasks = tasks.sort((a, b) => {
      return a.ticketId - b.ticketId
    })

    state.tasks = tasks

    // add tasks to tickets state
    const ticketIdx = state.tickets.findIndex(
      (ticket) => ticket.id === payload.ticketId
    )
    state.tickets[ticketIdx].tasks =
      state.tickets[ticketIdx].tasks.concat(new_tasks)
    // Add task to the columns
    const columns = state.columns
    const listIdx = columns.findIndex((l) => l.code === 'on-queue')
    let items = columns[listIdx].items
    items = items.concat(new_tasks)
    set(columns[listIdx], 'items', items)
  },
  EDIT_TASK_ITEMS(state, payload) {
    const idx = state.tasks.findIndex((task) => task.id === payload.task.id)
    state.tasks[idx] = payload.task

    const ticketIdx = state.tickets.findIndex(
      (ticket) => ticket.id === payload.ticket.id
    )
    state.tickets[ticketIdx] = payload.ticket
  },
  EDIT_TICKET_ITEMS(state, payload) {
    const idx = state.tickets.findIndex(
      (ticket) => ticket.id === payload.ticket.id
    )
    if (state.tickets[idx] === undefined) {
      const newData = payload.ticket
      newData.tasks = []
      state.tickets.push(payload.ticket)
    } else {
      payload.ticket.tasks = state.tickets[idx].tasks
      state.tickets[idx] = payload.ticket
    }
  },

  SET_TICKET_DATA(state, payload) {
    const tickets = setTicketTasks(payload.tickets, state.tasks)
    state.tickets = tickets
  },
  SET_TASK_DATA(state, payload) {
    // console.log('payload', payload)s
    const tasks = payload.tasks.sort((a, b) => {
      return a.ticketId - b.ticketId
    })

    state.tasks = tasks
  },

  SET_DETAIL_TASK(state, payload) {
    state.activeTask = payload
  },
  EDIT_DETAIL_TASK(state, payload) {
    state.activeTask = Object.assign({}, payload)
  },
  CLEAR_TASK(state, payload) {
    state.activeTask = {}
  },
  DELETE_TASK(state, payload) {
    try {
      const tasks = state.tasks.filter((task) => task.id !== payload.task.id)
      state.tasks = tasks

      const ticketIdx = state.tickets.findIndex(
        (ticket) => ticket.id === payload.task.ticketId
      )
      state.tickets[ticketIdx].tasks = state.tickets[ticketIdx].tasks.filter(
        (task) => task.id !== payload.task.id
      )
    } catch (error) {
      console.log(error)

      const user = this.$auth.$storage.getState('user')

      this.$sentry.setContext('user', user)
      this.$sentry.setTag('context', 'kanban store')
      this.$sentry.captureException(error)
    }
  },
  DELETE_TICKET(state, id) {
    const tasks = state.tasks.filter((task) => task.ticketId !== id)
    state.tasks = tasks

    const tickets = state.tickets.filter((ticket) => ticket.id !== id)
    state.tickets = tickets
  },
  LOAD_COOKIES_FILTER_PARAMS(state) {
    const filter_data = JSON.parse(getCookie('filter_data'))
    if (filter_data) {
      if (state.authUser.id === filter_data.authUserId) {
        state.filter_data = filter_data
      } else {
        eraseCookie('filter_data')
      }
    }
  },
  SET_COOKIES_FILTER_PARAMS(state) {
    const object_in_cookie = state.filter_data
    object_in_cookie.authUserId = state.authUser.id
    const params_srting = JSON.stringify(object_in_cookie)
    setCookie('filter_data', params_srting, 30)
  },
  FILTER_PARAMS(state, payload) {
    state.filter_data.designer = payload.designer
    state.filter_data.due_date = payload.due_date
    state.filter_data.type_of_item = payload.type_of_item
    state.filter_data.filtered = true
  },
  CLEAR_PARAMS(state) {
    state.filter_data.designer = ''
    state.filter_data.due_date = ''
    state.filter_data.type_of_item = ''
    state.filter_data.filtered = false
    eraseCookie('filter_data')
  },
  SET_SEARCHER_TEXT(state, payload) {
    state.searcherText = payload.searcherText
  },
  UPDATE_SINGLE_TICKET_PROPERTY(state, payload) {
    const index = state.tickets.findIndex(
      (ticket) => ticket.id === payload.ticketId
    )

    state.tickets[index][payload.propertyName] = payload.propertyValue
  },
  UPDATE_SINGLE_TASK_PROPERTY(state, payload) {
    const index = state.tasks.findIndex((task) => task.id === payload.taskId)

    state.tasks[index][payload.propertyName] = payload.propertyValue
  },
}

export const actions = {
  async fetchDataBoard({ commit, state, dispatch }) {
    try {
      commit('SET_LOADING_STATE', true)

      // Get filteredString cookie

      let nameEQ = 'filteredString='
      const ca = document.cookie.split(';')
      let filterStringSaved = ''
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') c = c.substring(1, c.length)
        if (c.indexOf(nameEQ) === 0)
          filterStringSaved = c.substring(nameEQ.length, c.length)
      }

      // Get filteredString cookie
      nameEQ = 'filtered='
      let filterSaved = ''
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') c = c.substring(1, c.length)
        if (c.indexOf(nameEQ) === 0)
          filterSaved = c.substring(nameEQ.length, c.length)
      }

      if (
        state.authUser.isDesigner > 1 ||
        state.authUser.isPl ||
        state.authUser.isAdmin
      ) {
        if (filterStringSaved !== 'null') {
          // console.log('if 298')
          try {
            const filtersApplied = JSON.parse(filterSaved)
            const payload = {
              type_of_item: filtersApplied.type_of_item,
              designer: filtersApplied.designer,
              due_date: {},
            }
            const newFilter = {
              filter: filterStringSaved,
            }
            const data = {
              data: newFilter,
              payload,
            }
            await dispatch('filterLoad', data)
          } catch (error) {
            console.error('asumo que hay un error')
            console.error(error)
            await dispatch('getTaskData')
            await dispatch('getTicketData')
          }
        } else {
          // console.log('else 318')
          await dispatch('getTaskData')
          await dispatch('getTicketData')
        }
      } else if (state.authUser.isDesigner === 1) {
        const payload = {
          type_of_item: 'tasks',
          designer: {},
          due_date: {},
        }
        commit('FILTER_PARAMS', payload)
        if (filterStringSaved === 'null') {
          await dispatch('getTicketData')

          const authUser = this.$auth.$storage.getState('user')
          const payloadTasks = {
            userId: authUser.sub,
            authToken: this.$auth.$storage.getUniversal('_token.cookie'),
            query: `&designer=${authUser.sub}`,
          }

          const response_tasks_per_designer = await this.$ticketMS.listTasks(
            payloadTasks
          )

          if (response_tasks_per_designer.success === false) {
            // eslint-disable-next-line no-undef
            toastr.error(
              'There was an error refreshing the kanban, please try again'
            )
            // console.log(response_tasks_per_designer)
          }

          const tasks_per_designer = response_tasks_per_designer.data

          // console.log(380, tasks_per_designer)

          commit('SET_TASK_DATA', { tasks: tasks_per_designer })
          commit('SET_LOADING_STATE', false)
        } else {
          try {
            const filtersApplied = JSON.parse(filterSaved)
            const payload = {
              type_of_item: 'tasks',
              designer: filtersApplied.designer,
              due_date: {},
            }
            const newFilter = {
              filter: filterStringSaved,
            }
            const data = {
              data: newFilter,
              payload,
            }
            await dispatch('filterLoad', data)
          } catch (error) {
            await dispatch('getTicketData')

            const authUser = this.$auth.$storage.getState('user')
            const payloadTasks = {
              userId: authUser.sub,
              authToken: this.$auth.$storage.getUniversal('_token.cookie'),
              query: `&designer=${authUser.sub}`,
            }

            const response_tasks_per_designer = await this.$ticketMS.listTasks(
              payloadTasks
            )

            if (response_tasks_per_designer.success === false) {
              // eslint-disable-next-line no-undef
              toastr.error(
                'There was an error refreshing the kanban, please try again'
              )
              // console.log(response_tasks_per_designer)
            }
            const tasks_per_designer = response_tasks_per_designer.data

            // console.log(380, tasks_per_designer)

            commit('SET_TASK_DATA', { tasks: tasks_per_designer })

            const payload = {
              type_of_item: 'tasks',
              designer: {},
              due_date: {},
            }
            commit('FILTER_PARAMS', payload)
          }
        }
      }
    } catch (error) {
      console.error('An error ocurred fetching kanban data', error)

      const user = this.$auth.$storage.getState('user')

      this.$sentry.setContext('user', user)
      this.$sentry.setTag('context', 'kanban store')
      this.$sentry.captureException(error)

      return {
        success: false,
        error,
      }
    }

    commit('FILL_COLUMNS')
    commit('SET_LOADING_STATE', false)
  },

  async getTicketData({ commit }) {
    const authUser = this.$auth.$storage.getState('user')
    const payload = {
      userId: authUser.sub,
      authToken: this.$auth.$storage.getUniversal('_token.cookie'),
      filter: `limitTime=true`,
    }

    const response_tickets = await this.$ticketMS.list(payload)
    const tickets = response_tickets.data
    // const tickets = ticketMockup.data;

    commit('SET_TICKET_DATA', { tickets })
  },

  async getTaskData({ commit }) {
    const authUser = this.$auth.$storage.getState('user')
    const payload = {
      userId: authUser.sub,
      authToken: this.$auth.$storage.getUniversal('_token.cookie'),
      query: ``,
    }

    const response_tasks = await this.$ticketMS.listTasks(payload)

    if (response_tasks.success === false) {
      // eslint-disable-next-line no-undef
      toastr.error('There was an error refreshing the kanban, please try again')
    }
    const tasks = response_tasks.data
    // console.log(466, tasks)
    commit('SET_TASK_DATA', { tasks })
  },

  async fetchDesigners({ commit }) {
    try {
      // const users_type = 'designer'
      // const url = '/api/users?type=' + users_type
      // const response = await axios.get(url)
      const payload = {
        userId: this.$auth.$storage.getState('user').sub,
        authToken: this.$auth.$storage.getCookie('_token.cookie'),
        query: 'type=designer',
      }

      const response = await this.$userMS.list(payload)

      if (!response.success) {
        throw new Error(`Error getting users: ${response.message} `)
      }

      // if (response.success) {
      const designers = response.data

      commit('SET_DESIGNERS', { designers })
      // }
    } catch (error) {
      console.error('An error ocurred', error)

      const user = this.$auth.$storage.getState('user')

      this.$sentry.setContext('user', user)
      this.$sentry.setTag('context', 'kanban store')
      this.$sentry.captureException(error)

      return {
        success: false,
        error,
      }
    }
  },

  async newTask({ commit, state }, data) {
    try {
      const designers = state.designers
      const new_tasks = data
      new_tasks.forEach((task) => {
        task.userName = designers.filter(
          (designer) => designer.id === task.userId
        )[0].name
        task.ticket = task.ticketId
      })

      await commit('ADD_TASK_TO_COLUMN', {
        new_tasks,
        ticketId: data[0].ticketId,
      })
    } catch (error) {
      // console.log(error)

      const user = this.$auth.$storage.getState('user')

      this.$sentry.setContext('user', user)
      this.$sentry.setTag('context', 'kanban store')
      this.$sentry.captureException(error)
    }
  },

  async addTask({ commit, state }, payload) {
    // const url = '/api/tasks'
    const designers = state.designers
    const authUser = this.$auth.$storage.getState('user')
    const petitionPayload = {
      userId: authUser.sub,
      authToken: this.$auth.$storage.getUniversal('_token.cookie'),
      payload: payload.taskData,
    }

    const response = await this.$ticketMS.storeTask(petitionPayload)

    if (response.success) {
      const new_tasks = response.data
      new_tasks.forEach((task) => {
        task.userName = designers.filter(
          (designer) => designer.id === task.userId
        )[0].name
        task.ticket = payload.ticketData
      })

      await commit('ADD_TASK_TO_COLUMN', {
        new_tasks,
        ticketId: payload.ticketData.id,
      })
    } else {
      console.error('There is an error creating task', response.message)

      throw new Error(`Invalid request to create task: ${response.message}`)
    }
  },

  async updateTaskStatus({ commit }, payload) {
    const url = '/api/tasks/' + payload.item_id
    const result = await axios.put(url, { task_data: payload.task_data })

    if (!result.data.success) {
      console.error('There is an error updating task', result)
      throw new Error('Invalid request to update task')
    }
  },

  async filterLoad({ commit, state, dispatch }, data) {
    commit('SET_LOADING_STATE', true)
    if (
      state.authUser.isDesigner === 1 &&
      !state.authUser.isPl &&
      !state.authUser.isAdmin
    ) {
      data.payload.type_of_item = 'tasks'
    }

    if (
      data.payload.type_of_item === 'tasks' ||
      data.payload.type_of_item === 'task'
    ) {
      await dispatch('getTicketData')

      const authUser = this.$auth.$storage.getState('user')
      const payload = {
        userId: authUser.sub,
        authToken: this.$auth.$storage.getUniversal('_token.cookie'),
        query: data.data.filter,
      }

      const response_tasks = await this.$ticketMS.listTasks(payload)

      // const response_tasks = await axios.put('tasks/filter', data.data)
      if (response_tasks.success === false) {
        // eslint-disable-next-line no-undef
        toastr.error(
          'There was an error refreshing the kanban, please try again'
        )
        // console.log(response_tasks)
      }
      const tasks = response_tasks.data
      data.payload.type_of_item = 'tasks'
      // console.log(598, tasks)
      commit('SET_TASK_DATA', { tasks })
    } else if (
      data.payload.type_of_item === 'ticket' &&
      (state.authUser.isDesigner > 1 ||
        state.authUser.isPl ||
        state.authUser.isAdmin)
    ) {
      await dispatch('getTaskData')

      const authUser = this.$auth.$storage.getState('user')
      const payloadTickets = {
        userId: authUser.sub,
        authToken: this.$auth.$storage.getUniversal('_token.cookie'),
        filter: `${data.data.filter}`,
      }

      const response_tickets = await this.$ticketMS.list(payloadTickets)

      if (response_tickets.success === false) {
        // eslint-disable-next-line no-undef
        toastr.error(
          'There was an error refreshing the kanban, please try again'
        )
        // console.log(response_tickets)
      }
      const tickets = response_tickets.data

      data.payload.type_of_item = 'tickets'

      commit('SET_TICKET_DATA', { tickets })
    } else if (
      data.payload.type_of_item === 'ticket' &&
      state.authUser.isDesigner === 1
    ) {
      document.cookie = 'filtered' + '=' + null + '; path=/'
      document.cookie = 'filteredString' + '=' + null + '; path=/'

      // const response_tasks = await axios.get(
      //   'api/tasks?designer=' + state.authUser.id
      // )
      const authUser = this.$auth.$storage.getState('user')
      const payloadTasks = {
        userId: authUser.sub,
        authToken: this.$auth.$storage.getUniversal('_token.cookie'),
        query: `&designer=${authUser.sub}`,
      }

      const response_tasks = await this.$ticketMS.listTasks(payloadTasks)

      if (response_tasks.success === false) {
        // eslint-disable-next-line no-undef
        toastr.error(
          'There was an error refreshing the kanban, please try again'
        )
        // console.log(response_tasks)
      }
      const tasks = response_tasks.data
      // console.log(646, tasks)
      commit('SET_TASK_DATA', { tasks })
    } else if (
      state.authUser.isDesigner > 1 ||
      state.authUser.isPl ||
      state.authUser.isAdmin
    ) {
      // // console.log(`state.authUser.isDesigner > 1 ||
      // state.authUser.isPl ||
      // state.authUser.isAdmin`)

      // console.log(data.data)

      // console.log('filter 681')

      const authUser = this.$auth.$storage.getState('user')
      const payloadTasks = {
        userId: authUser.sub,
        authToken: this.$auth.$storage.getUniversal('_token.cookie'),
        query: data.data.filter,
      }
      const payloadTickets = {
        userId: authUser.sub,
        authToken: this.$auth.$storage.getUniversal('_token.cookie'),
        filter: `${data.data.filter}`,
      }

      const response_tasks = await this.$ticketMS.listTasks(payloadTasks)
      const response_tickets = await this.$ticketMS.list(payloadTickets)

      // const response_tasks = await axios.put('tasks/filter', data.data)
      // const response_tickets = await axios.put('tickets/filter', data.data)
      if (
        response_tasks.success === false ||
        response_tickets.success === false
      ) {
        console.error(response_tickets)
        console.error(response_tasks)
        // eslint-disable-next-line no-undef
        toastr.error(
          'There was an error refreshing the kanban, please try again'
        )
      }
      const tasks = response_tasks.data
      // console.log(669, tasks)
      commit('SET_TASK_DATA', { tasks })
      const tickets = response_tickets.data
      commit('SET_TICKET_DATA', { tickets })
    } else {
      // console.log(`else`)
      const response_tasks = await axios.put('tasks/filter', data.data)
      if (response_tasks.data.success === false) {
        // eslint-disable-next-line no-undef
        toastr.error(
          'There was an error refreshing the kanban, please try again'
        )
        // console.log(response_tasks)
      }
      const tasks = response_tasks.data.data
      // console.log(683, tasks)
      commit('SET_TASK_DATA', { tasks })
    }

    commit('FILTER_PARAMS', data.payload)
    // console.log('fill_column 618')
    commit('FILL_COLUMNS')
    commit('SET_LOADING_STATE', false)
  },

  async removeTask({ commit, state }, payload) {
    const authUser = this.$auth.$storage.getState('user')
    const petitionPayload = {
      userId: authUser.sub,
      authToken: this.$auth.$storage.getUniversal('_token.cookie'),
      id: payload.task.id,
    }

    const result = await this.$ticketMS.deleteTask(petitionPayload)

    if (!result.success) {
      console.error('There is an error removing task', result)
      throw new Error(`Invalid request to delete task: ${result.message}`)
    } else {
      await commit('DELETE_TASK', { task: payload.task })
      // console.log('fill_column 636')
      await commit('FILL_COLUMNS')
    }
  },
  async updateTicket({ commit }, payload) {
    const authUser = this.$auth.$storage.getState('user')
    const payloadPetition = {
      userId: authUser.sub,
      authToken: this.$auth.$storage.getUniversal('_token.cookie'),
      payload: payload.ticket_data,
      id: payload.ticket_id,
    }

    const response = await this.$ticketMS.put(payloadPetition)

    if (!response.success) {
      console.error('There is an error updating ticket', response.message)
      throw new Error(`Invalid request to update ticket ${response.message}`)
    } else {
      return response
    }
  },

  cleanItemsToSend({ commit }) {
    commit('CLEAM_ITEMS_SENT')
  },

  refreshDataInSameColumn({ commit }, payload) {
    commit('REFRESH_DATA_SAME_COLUMN', payload)
  },

  refreshDataInOtherColumn({ commit }, payload) {
    commit('REFRESH_DATA_OTHER_COLUMN', payload)
  },
  reorderTaskListItemsBoard({ commit }, payload) {
    commit('REORDER_TASKLIST_ITEMS_BOARD', payload)
  },
}
