import { isEmpty } from 'lodash'

/* eslint-disable no-console */
export const state = () => ({
  currentUser: {},
  gettingFeatures: false,
  allFeatures: [],
  userAccount: {},
  userSubscriptions: [],
  userFeatures: [],
  features: {},
  mostUsedCodeList: [],
  isRepurposing: undefined,
  usedFeatures: [],
  trendingList: [],
  buttonDisabledModal: false,
  reasons: {},
})

export const getters = {
  isPro(state) {
    if (isEmpty(state.userAccount)) return undefined

    return state.userAccount.is_pro ? state.userAccount.is_pro : 0
  },
  status(state) {
    if (isEmpty(state.userAccount)) return undefined

    return state.userAccount.status ? state.userAccount.status : undefined
  },
  transactions(state) {
    if (isEmpty(state.userAccount)) return []

    return state.userAccount.transactions ? state.userAccount.transactions : []
  },
  symbol(state) {
    if (state.currentUser && state.currentUser.currency === 'eur') {
      return '€'
    }
    if (state.currentUser && state.currentUser.currency === 'gbp') {
      return '£'
    }
    return '$'
  },
  features(state) {
    if (isEmpty(state.userAccount)) return []

    return state.userAccount.features ? state.userAccount.features : undefined
  },
  subscriptions: (state) => state.userSubscriptions,
}

export const mutations = {
  setData(state, payload) {
    payload.forEach((element) => {
      state[element.property] = element.data
    })
  },
  deleteSubscriptions(state, payload) {
    state.annotations = state.userSubscriptions.filter(
      (subscription) => subscription.stripe_id !== payload.stripe_id
    )
  },

  arraysToObject(state, payload) {
    Object.keys(state.payload).forEach((key) => {
      const arr = state.payload[key]
      const obj = arr.reduce(function (acc, cur) {
        acc[cur.feature_code] = cur
        return acc
      }, {})
      state.payload[key] = obj
    })
  },

  addDescriptionsToSubs(state, payload) {
    // state.userSubscriptions.forEach

    state.userSubscriptions.forEach((subscription, index) => {
      subscription.description = payload.descriptions[index]
      // this.$set(
      //   this.userSubscriptions[index],
      //   'description',
      //   payload.descriptions[index]
      // )
    })
  },
}

export const actions = {
  fetchCounter({ state }) {
    // make request
    const res = { data: 10 }
    state.counter = res.data
    return res.data
  },
  async getDataUser({ commit }, { payload }) {
    // console.log('payload ', payload)
    const response = await this.$accountMS.user(payload)
    // console.log('response userId', response)
    if (!response.success) {
      throw new Error(`Error getting data user: ${response.message}`)
    }

    commit('setData', [{ property: 'currentUser', data: response.data }])

    // this.loading = false
  },
  async getUserAccount({ dispatch }, { payload }) {
    // console.log('payload', payload)
    const response = await this.$accountMS.show(payload)

    if (!response.success) {
      const user = this.$auth.$storage.getState('user')

      if (!user.isEmployee) {
        throw new Error(`Error getting account data: ${response.message}`)
      }
    }

    // callback
    await dispatch('setUserAccount', response)
  },
  setUserAccount({ commit }, accountData) {
    // console.log('accountData', accountData)
    if (
      !accountData.success ||
      accountData.success === false ||
      accountData.success === 'false'
    ) {
      const user = this.$auth.$storage.getState('user')

      if (!user.isEmployee) {
        throw new Error(
          `User subscription not found - account store: ${accountData.message}`
        )
      }
    }

    const { data } = accountData

    // console.log(accountData)

    // set data in store
    commit('setData', [{ property: 'userAccount', data: data[0] }])
    commit('setData', [
      {
        property: 'userSubscriptions',
        data: data.length ? data[0].subscriptions : [],
      },
    ])
    commit('setData', [
      {
        property: 'userFeatures',
        data: data.length ? data[0].features : [],
      },
    ])
  },
}
