
import { mapMutations } from 'vuex'
// import { cloneDeep } from 'lodash'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
  components: { VueCropper },

  props: {
    userImage: {
      required: true,
      type: String,
    },
    mini: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dialog: false,
      loading: false,
      imageSrc: '',
      image: this.userImage,
      file: undefined,
    }
  },

  computed: {
    payload() {
      return {
        userId: this.$auth.$storage.getState('user').sub,
        authToken: this.$auth.$storage.getCookie('_token.cookie'),
      }
    },
  },

  methods: {
    ...mapMutations('toast', {
      setToast: 'SET_TOAST',
    }),

    triggerFile() {
      document.getElementById('file-upload').click()
    },

    setImage(e) {
      const file = e.target.files[0]
      this.file = file

      this.dialog = true

      if (!file.type.includes('image/')) {
        this.setToast({
          message: 'Please select an image file',
          type: 'error',
        })
      }

      if (typeof FileReader === 'function') {
        const reader = new FileReader()

        reader.onload = (event) => {
          this.imageSrc = event.target.result

          // Rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result)
        }
        reader.readAsDataURL(file)
      } else {
        // alert('Sorry, FileReader API not supported');
        this.setToast({
          message: 'Sorry, FileReader API not supported',
          type: 'error',
        })
      }
    },

    closeModal() {
      this.imageSrc = ''
      this.dialog = false
    },

    // eslint-disable-next-line require-await
    async uploadImage() {
      this.loading = true
      try {
        const canvas = this.$refs.cropper.getCroppedCanvas()
        const authUser = this.$auth.$storage.getState('user')
        // const userCloned = cloneDeep(authUser)

        const payload = {
          userId: authUser.sub,
          authToken: this.$auth.$storage.getUniversal('_token.cookie'),
          payload: {
            img: canvas.toDataURL(),
            filename: this.file.name,
            userId: authUser.sub,
          },
        }

        const { data, success, message } =
          await this.$userMS.uploadProfilePhoto(payload)

        if (!success) throw new Error(message)

        this.image = data.image

        await this.submitLogin()

        this.closeModal()
      } catch (error) {
        const user = this.$auth.$storage.getState('user')

        this.$sentry.setContext('user', user)
        this.$sentry.setTag('context', 'profileImage - upload photo')
        this.$sentry.captureException(error)

        this.setToast({
          message: error.message,
          type: 'error',
          timeout: 5000,
        })
      } finally {
        this.loading = false
      }
    },

    async submitLogin() {
      const { authToken, userId } = this.payload
      const newPayload = {
        authToken,
        userId,
        loginUserId: userId,
      }

      const response = await this.$userMS.auth(newPayload)

      if (!response.success) {
        this.setToast({
          type: 'error',
          message: `Couldn't change authentication`,
        })
        throw new Error(`Error login with auth method`)
      }

      // sets user's data from the token in store
      this.$auth.setUser(jwt_decode(response.data))
      this.$auth.strategy.token.set(response.data)
    },
  },
}
