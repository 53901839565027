export const state = () => ({
  awsData: {},
  awsLambda: undefined,
  awsKey: undefined,
  authUser: undefined,
  whitelabel: undefined,
  showFeatureCodeModal: false,
  cannedResponsesModal: false,
  videoAnnotationModal: false,
  loomModal: false,
  fileViewerKeyIdx: 1,
})

export const getters = {
  getUser: (state) => state.user,
  isPl: (state) => state.authUser.isPl ?? 0,
  isDesigr: (state) => state.authUser.isDesigner ?? 0,
}

export const mutations = {
  setData(state, payload) {
    payload.forEach((element) => {
      state[element.property] = element.data
    })
  },

  setAuthUserData(state, payload) {
    payload.forEach((element) => {
      state.authUser[element.property] = element.data
    })
  },

  setAWSData(state, payload) {
    state.awsLambda = payload.awsLambda
    state.awsKey = payload.awsKey
  },
}

export const actions = {
  resetApp({ commit, state }) {
    commit('files/resetFileAMApprove', {}, { root: true })
    commit(
      'files/setDeliverableFilesData',
      { deliverablesFiles: [] },
      { root: true }
    )
    commit('setData', [
      { property: 'fileViewerKeyIdx', data: state.fileViewerKeyIdx + 1 },
    ])

    // state.fileViewerKeyIdx++
  },
}
