import { render, staticRenderFns } from "./SideBar.vue?vue&type=template&id=679659c3&scoped=true&"
import script from "./SideBar.vue?vue&type=script&lang=js&"
export * from "./SideBar.vue?vue&type=script&lang=js&"
import style0 from "./SideBar.vue?vue&type=style&index=0&id=679659c3&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "679659c3",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProfileImage: require('/codebuild/output/src985175027/src/nuxt-thefutures/components/ProfileImage.vue').default})
