
import { mapState, mapMutations, mapGetters } from 'vuex'

export default {
  name: 'ToastNotification',
  computed: {
    ...mapState('toast', ['snackbarNotification']),
    ...mapGetters('toast', ['showSnackbarNotification']),

    showSnackbar: {
      get() {
        return this.showSnackbarNotification
      },
      set(val) {
        this.setShow(val)
      },
    },
  },

  watch: {
    showSnackbar(val) {
      if (val) {
        if (this.snackbarNotification.timeout === -1) {
          return
        }

        setTimeout(() => {
          this.setShow({ show: false })
        }, this.snackbarNotification.timeout)
      }
    },
  },

  methods: {
    ...mapMutations('toast', {
      setShow: 'SET_SHOW_NOTIFICATION',
    }),
  },
}
