// function URL(ticketId, commentId = '') {
//   let url = `/api/tickets/${ticketId}/comments`

//   if (commentId) url += `/${commentId}`

//   return url
// }

// eslint-disable-next-line no-unused-vars
function scrollBottom(channel) {
  setTimeout(() => {
    const element = document.querySelector(`.messages-wrapper-${channel}`)

    if (!element) return

    document.querySelector(`.messages-wrapper-${channel}`).scroll({
      top: 9999,
    })
  })
}

export const state = () => ({
  loading: {
    public: false,
    internal: false,
  },
  error: null,
  messages: {
    public: [],
    internal: [],
  },
  offset: {
    public: 0,
    internal: 0,
  },

  channel: '',
  participants: [],
  messageToSend: {},
  message: '',
  isSending: false,
  loadingFile: false,
  modalRevisionMessage: false,
})

export const getters = {
  // getUser: (state) => state.user,
}

export const mutations = {
  SHOW_MODAL_REVISION_MESSAGE(state, payload) {
    state.modalRevisionMessage = payload
  },

  SET_PARTICIPANTS(state, payload) {
    state.participants = payload
  },
  SET_MESSAGES(state, { channel, messages }) {
    state.messages[channel] = [...messages, ...state.messages[channel]]
  },
  PUSH_MESSAGE(state, { channel, message }) {
    state.messages[channel].push(message)
  },
  EDIT_MESSAGE(state, { index, data: message, channel }) {
    state.messages[channel].splice(index, 1, {
      ...state.messages[channel][index],
      ...message,
    })
  },
  DELETE_MESSAGE(state, { index, data: message, channel }) {
    message = {
      ...message,
      deletedAt: new Date().toISOString(),
    }
    state.messages[channel].splice(index, 1, {
      ...state.messages[channel][index],
      ...message,
    })
  },
  SET_IS_SENDING(state, payload) {
    state.isSending = payload.isSending
  },
  SET_CHANNEL(state, payload) {
    state.channel = payload
  },
  REMOVE_MESSAGE(state, { channel, index }) {
    state.messages[channel].splice(index, 1)
  },
  SET_LOADING(state, payload) {
    state.loading[state.channel] = payload
  },
  ADD_MESSAGE_TO_SEND(state, payload) {
    state.messageToSend = payload
  },
  CLEAN_MESSAGE_TO_SEND(state) {
    state.messageToSend = {}
    state.message = ''
  },
  SET_MESSAGE(state, payload) {
    state.message = payload
  },
  SET_LOADING_FILE(state, payload) {
    state.loadingFile = payload
  },
  SET_OFFSET(state, { channel, data }) {
    if (data === null) {
      state.offset[channel] = data
    } else {
      state.offset[channel] += data
    }
  },
  RESET_CHAT(state) {
    state.offset = {
      public: 0,
      internal: 0,
    }

    state.messages = {
      public: [],
      internal: [],
    }
  },
}

export const actions = {
  async updateAttended({ state, rootState }) {
    const user = this.$auth.$storage.getState('user')
    const { id } = rootState.ticket.ticket
    const userId = user.sub

    const payload = {
      userId,
      authToken: this.$auth.$storage.getUniversal('_token.cookie'),
      id,
      payload: {
        attended: rootState.user.user.isPl ? 1 : 0,
      },
    }

    const { success, message } = await this.$ticketMS.put(payload)

    if (!success) {
      throw new Error(`Error marking ticket as attended: ${message}`)
    }
  },

  async getMessages({ state, commit, rootState }, channel = state.channel) {
    if (state.offset[channel] === null || state.loading[channel]) return
    commit('SET_LOADING', true)
    commit('SET_MESSAGES', { channel, messages: [] })

    const authUser = this.$auth.$storage.getState('user')

    const response = await this.$axios.$get(
      `/public/tickets/${rootState.ticket.ticket.id}/comments?channel=${channel}&offset=${state.offset[channel]}&limit=8&withDeleted=true`,
      {
        headers: {
          Authorization: this.$auth.$storage.getUniversal('_token.cookie'),
          'User-Id': authUser.sub,
        },
      }
    )

    const { data } = response

    const messages = data.comments.reverse()

    const { next } = data

    if (next) {
      commit('SET_OFFSET', { channel, data: 10 })
      // state.offset[channel] += 10
    } else {
      // state.offset[channel] = null
      commit('SET_OFFSET', { channel, data: null })
    }

    commit('SET_MESSAGES', { channel, messages })
    commit('SET_LOADING', false)

    if (state.messages[channel].length <= 10) scrollBottom(channel)
  },

  // eslint-disable-next-line require-await
  async sendMessage(
    { state, commit, dispatch, rootState },
    { message, files = null, channel = 'public' }
  ) {
    // eslint-disable-next-line no-unused-vars
    const ticketId = rootState.ticket.ticket.id

    const initialMessage = {
      comment: message,
      userId: rootState.fileViewer.authUser.id,
      user: rootState.fileViewer.authUser,
      createdAt: new Date().toISOString(),
      files,
    }

    commit('PUSH_MESSAGE', { channel, message: initialMessage })

    const index = state.messages[channel].length - 1

    setTimeout(() => {
      // eslint-disable-next-line no-undef
      $('.messages-wrapper').scrollTop(9999)
    }, 200)

    try {
      if (files && files.length) {
        commit('SET_LOADING_FILE', true)
      }

      const commentData = {
        comment: message,
        ticketId,
        userId: rootState.fileViewer.authUser.id,
        channel,
        userIsPl: rootState.fileViewer.authUser.isPl >= 1,
        // limit: 10,
      }

      const payload = {
        userId: rootState.fileViewer.authUser.id,
        authToken: this.$auth.$storage.getUniversal('_token.cookie'),
        ticketId,
        payload: commentData,
      }

      // const response = await this.$axios.post(URL(ticketId), {
      //   message,
      //   channel,
      //   isPl: rootState.init.authUser.is_pl,
      //   userId: rootState.init.authUser.id,
      // })

      const response = await this.$ticketMS.storeComment(payload)

      const { data } = response

      data.files = files

      commit('EDIT_MESSAGE', {
        index,
        data,
        channel,
      })

      await dispatch('updateAttended')

      if (files && files.length) {
        dispatch('uploadFile', {
          files,
          ticketId,
          commentId: data.id,
          index,
          channel,
        })
      } else {
        commit('SET_LOADING_FILE', false)
      }

      return true
    } catch (error) {
      // eslint-disable-next-line no-console
      // console.error(error)
      const user = this.$auth.$storage.getState('user')

      this.$sentry.setContext('user', user)
      this.$sentry.setTag('context', 'messages store')
      this.$sentry.captureException(error)

      // this.toastr.error(
      //   "Couldn't send message, please try again or refresh the page"
      // )
      commit('SET_LOADING_FILE', false)

      return false
    }

    // dispatch('init/resetApp', { root: true });
  },

  // eslint-disable-next-line require-await
  async uploadFile(
    { commit, rootState },
    { files, ticketId, commentId, index, channel }
  ) {
    try {
      commit('SET_LOADING_FILE', true)
      commit(
        'toast/SET_TOAST',
        {
          type: 'info',
          message: `Your files are being uploaded. Please don't refresh the page until it's done.`,
        },
        { root: true }
      )

      const payload = {
        userId: rootState.fileViewer.authUser.id,
        authToken: this.$auth.$storage.getUniversal('_token.cookie'),
        payload: files.map((file) => {
          // cleans special chars
          let filename = file.name
            .normalize('NFD')
            .replace(/\p{Diacritic}/gu, '')

          // removes whitespace
          filename = filename.replace(/\s/g, '-')

          // remove curly quotes
          filename = filename
            .replace(/[\u2018\u2019]/g, "'")
            .replace(/[\u201C\u201D]/g, '"')

          return {
            file: file.url,
            filename,
            commentId,
            userId: rootState.fileViewer.authUser.id,
            ticketId,
          }
        }),
      }

      const { success, data } = await this.$fileMS.chatFileUpload(payload)

      if (success) {
        commit('EDIT_MESSAGE', {
          index,
          data: { files: data },
          channel,
        })
      }
    } catch (error) {
      const user = this.$auth.$storage.getState('user')

      this.$sentry.setContext('user', user)
      this.$sentry.setTag('context', 'messages store')
      this.$sentry.captureException(error)

      commit(
        'toast/SET_TOAST',
        {
          type: 'error',
          message: `Error uploading file: ${error.message}`,
        },
        { root: true }
      )
    } finally {
      commit('SET_LOADING_FILE', false)
    }
  },
  async removeMessage({ state, commit, rootState }, message) {
    const payload = {
      userId: rootState.fileViewer.authUser.id,
      authToken: this.$auth.$storage.getUniversal('_token.cookie'),
      id: message,
    }

    const index = state.messages[state.channel].findIndex(
      (m) => m.id === message.id
    )
    // commit('REMOVE_MESSAGE', { channel: state.channel, index })
    // const payload = {
    //   userId: rootState.fileViewer.authUser.id,
    //   authToken: this.$auth.$storage.getUniversal('_token.cookie'),
    //   commentId: message.id,
    //   payload: commentData,
    // }

    // const payload = {
    //   userId: rootState.fileViewer.authUser.id,
    //   authToken: this.$auth.$storage.getUniversal('_token.cookie'),
    //   commentId: 12,
    //   payload: commentData,
    // }

    // const index = state.messages[state.channel].findIndex(
    //   (m) => m.id === message.id
    // )
    const dataMessage = message
    // commit('REMOVE_MESSAGE', { channel: state.channel, index });
    commit('DELETE_MESSAGE', { index, dataMessage, channel: state.channel })
    //
    // const payload = {
    //   userId: rootState.fileViewer.authUser.id,
    //   authToken: this.$auth.$storage.getUniversal('_token.cookie'),
    //   messageId,
    //   payload: message,
    // }
    await this.$ticketMS.deleteComment(payload)
  },
}
