/**
 * register page middleware
 *
 * redirects from ---old route--- register/design/{plan}/{quantity}
 * to ---new route--- register/{plan}
 *
 */

export default function (context) {
  return context.redirect(`/register/${context.route.params.plan}`)
}
