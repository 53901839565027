export default ({ app, $axios, $config }, inject) => {
  async function getFeaturesFiltered(filter) {
    const response = await $axios.$get(
      `${$config.baseURL}/hr/features${filter}`
    )

    return response
  }

  async function getTemplates({ userId, authToken, featureCode }) {
    const response = await $axios.$get(
      `${$config.baseURL}/public/features/template/${featureCode}`,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )

    if (!response.success) {
      throw new Error(`Error getting templates for ${featureCode}`)
    }

    return response.data
  }

  async function showFeature({ userId, authToken, featureCode }) {
    const response = await $axios.$get(
      `${$config.baseURL}/public/features/${featureCode}`,
      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )

    if (!response.success) {
      throw new Error(`Error getting feature ${featureCode}`)
    }

    return response.data
  }

  const wizardsMS = {
    getFeaturesFiltered,
    getTemplates,
    showFeature,
  }

  inject('wizardsMS', wizardsMS)
}
