/* eslint-disable no-console */
/* eslint-disable camelcase */
const status_without_dueby = ['waiting-for-approval', 'paused', 'closed']
let tickets_stack = []
let tasks_stack = []

const getColumnsIndex = (columns) => {
  const columns_index = {}
  columns.forEach((column, idx) => {
    columns_index[column.code] = idx
  })
  return columns_index
}

const fillColumns = (
  base_columns,
  tickets,
  tasks,
  filter_data = {},
  searcherText = '',
  user
) => {
  const new_columns = JSON.parse(JSON.stringify(base_columns))
  const columns_index = getColumnsIndex(new_columns)
  const dueby_stack = []

  tickets_stack = tickets
  tasks_stack = tasks

  if (filter_data.type_of_item === 'tickets') {
    tasks_stack = []
  } else if (filter_data.type_of_item === 'tasks') {
    tickets_stack = []
  }

  const items = tickets_stack.length + tasks_stack.length
  let count = 0
  while (items > count) {
    count++
    const element_stack = extractElement()

    element_stack.status = statusFormatted(element_stack.status)
    const idx = columns_index[element_stack.status]

    if (element_stack.status && typeof idx !== 'undefined') {
      if (
        !validateFilter(element_stack, searcherText, filter_data) ||
        !new_columns[idx].active
      ) {
        continue
      }

      if (element_stack.dueBy) {
        dueby_stack.push(element_stack)
      } else {
        new_columns[idx].items.push(element_stack)
      }
    }
    // if ('on-queue') {
    // }
  }
  const merge_columns = orderDueByColumns(
    dueby_stack,
    new_columns,
    columns_index
  )
  if (!user.isAdmin && !user.isPl && user.isDesigner === 1) {
    if (
      merge_columns[1].items.length > 0 ||
      merge_columns[2].items.length > 0
    ) {
      console.log('no ismoveable')

      merge_columns[0].isMoveable = false
    } else {
      console.log('ismoveable')

      merge_columns[0].isMoveable = true
    }
    merge_columns[0].isDesigner = true
    merge_columns[0].id = 0
    console.log('ismoveable', merge_columns[0].isMoveable)
    for (let i = 1; i < 8; i++) {
      merge_columns[i].isDesigner = true
      merge_columns[i].id = i
      merge_columns[i].isMoveable = true
    }
    // merge_columns[1].isMoveable = true;
    // merge_columns[2].isMoveable = true;

    // else {
    //   new_columns[0].isMoveable = false;
    // }
  } else {
    for (let i = 0; i < 8; i++) {
      merge_columns[i].isDesigner = false
      merge_columns[i].isMoveable = true
    }
  }

  // 1. Buscar si existe tasks en las columnas being-checked y we-have-questions
  // merge_columns[0].items[0].canMove = true;

  return merge_columns
}

const extractElement = () => {
  let element_stack = {}
  const last_ticket = tickets_stack.length
    ? tickets_stack[tickets_stack.length - 1]
    : null
  const last_task = tasks_stack.length
    ? tasks_stack[tasks_stack.length - 1]
    : null

  if (last_task) {
    last_task.isTask = true
  }

  if (!last_ticket || !last_task) {
    element_stack = !last_task ? tickets_stack.pop() : tasks_stack.pop()
  } else {
    element_stack =
      last_ticket.id >= last_task.ticketId
        ? tickets_stack.pop()
        : tasks_stack.pop()
  }
  return element_stack
}

const orderDueByColumns = (dueby_stack, columns, columns_index) => {
  // sort dueBy in descencing order
  dueby_stack.sort(function (a, b) {
    return new Date(b.dueBy) - new Date(a.dueBy)
  })

  // put items with dueBy above the others that have dueBy null in the corresponding column
  dueby_stack.forEach((item) => {
    const idx = columns_index[item.status]
    columns[idx].items.unshift(item)
  })
  return columns
}

const validateFilter = (element_stack, searcherText, filter_data) => {
  // console.log('filter_data', filter_data)
  let isValid = true
  if (searcherText.length > 0 && !checkText(searcherText, element_stack)) {
    isValid = false
  }

  if (
    element_stack.isTask &&
    Object.keys(filter_data.designer).length &&
    element_stack.userId !== filter_data.designer.id
  ) {
    isValid = false
  }

  if (filter_data.due_date && Object.keys(filter_data.due_date).length) {
    const due_date_interval = getDueDateInterval(filter_data.due_date) // Object
    if (
      (!checkFilterDueBy(element_stack, due_date_interval) &&
        filter_data.due_date.value !== '') ||
      status_without_dueby.includes(element_stack.status)
    ) {
      isValid = false
    }
  }
  return isValid
}

const checkText = (text, item) => {
  let exists = false
  const len = text.length

  const id =
    item.id.toString().length >= len ? item.id.toString().substring(0, len) : ''
  if (item.isTask) {
    const ticket_id =
      item.ticketId.toString().length >= len
        ? item.ticketId.toString().substring(0, len)
        : ''
    if (id === text || ticket_id === text) {
      exists = true
    }
  } else {
    const title =
      item.title && item.title.length >= len
        ? item.title.substring(0, len).toLowerCase()
        : ''

    let company_name = ''

    if (item.company) {
      const company_name_length = item.company.name
        ? item.company.name.length
        : 0
      company_name =
        company_name_length >= len
          ? item.company.name.substring(0, len).toLowerCase()
          : ''
    }

    let client_name = ''
    if (item.client) {
      const client_name_length = item.client.name ? item.client.name.length : 0
      client_name =
        client_name_length >= len
          ? item.client.name.substring(0, len).toLowerCase()
          : ''
    }

    if (
      id === text ||
      title === text.toLowerCase() ||
      company_name === text.toLowerCase() ||
      client_name === text.toLowerCase()
    ) {
      exists = true
    }
  }
  return exists
}

const checkFilterDueBy = (ticket, due_date) => {
  let exists = false

  if (ticket.dueBy) {
    const ticket_dueby_date = new Date(ticket.dueBy)
    if (
      ticket_dueby_date > due_date.from &&
      ticket_dueby_date < due_date.until
    ) {
      exists = true
    }
  }
  return exists
}

const getDueDateInterval = (due_date) => {
  const interval = {
    from: 0,
    until: 0,
  }
  const now = new Date()
  const tomorrow = new Date(now)
  tomorrow.setDate(tomorrow.getDate() + 1)
  if (due_date.value === 'today') {
    interval.from = new Date(0)

    // end of the day today
    interval.until = new Date(now).setHours(23, 59, 59, 999)
  } else if (due_date.value === 'tomorrow') {
    interval.from = tomorrow.setHours(0, 0, 0, 0)
    interval.until = new Date(tomorrow).setHours(23, 59, 59, 999)
  } else if (due_date.value === 'this_week') {
    // filter the remaining days of the week
    interval.from = new Date(tomorrow).setHours(23, 59, 59, 999)
    interval.until = new Date(tomorrow).setDate(tomorrow.getDate() + 6)
  }

  return interval
}

const setTicketTasks = (tickets, tasks) => {
  return tickets.map((ticket) => {
    ticket.tasks = tasks.filter((task) => task.ticketId === ticket.id)
    return ticket
  })
}

const statusFormatted = (status) => {
  if (status) {
    return status.replace(/\s+/g, '-').toLowerCase()
  }

  return 'unknown'
}

export { fillColumns, setTicketTasks }
