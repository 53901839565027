/* eslint-disable no-console */
import jwtDecode from 'jwt-decode'

export default async function ({ $auth, redirect }) {
  if ($auth.strategy.token.status().expired()) {
    // logs out
    await $auth.logout()

    // to remove explicitely the redirect key from local storage
    $auth.$storage.removeUniversal('redirect')
  }

  /***
   * gets token from cookie
   * this is useful as there's no way to persist the token
   * for example when refreshing the page
   */
  const cookie = $auth.$storage.getCookie('_token.cookie')

  // if the token exists, the user is set again universally
  if (cookie) {
    $auth.setUser(jwtDecode(cookie.substring(7)))
  }
}
