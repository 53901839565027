/**
 * register page middleware
 *
 * redirects from ---old route--- billing/design/{plan}/{quantity}
 * to ---new route--- billing/{plan}
 *
 */

export default function (context) {
  return context.redirect(`/billing/${context.route.params.plan}`)
}
