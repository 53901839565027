// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'

export default function (context) {
  const authToken = context.$auth.$storage.getCookie('auth_token')

  if (process.client && authToken) {
    // sets token in storage
    context.$auth.$storage.setLocalStorage('auth_token', authToken)

    // sets token in store
    context.$auth.$storage.setState('auth_token', authToken)

    // sets user's data from the token in store
    context.$auth.setUser(jwt_decode(authToken))

    // sets loggedIn in store
    context.$auth.$storage.setState('loggedIn', true)

    return context.redirect('/tickets')
  }
}
