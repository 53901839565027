export default ({ app, $axios, $config }, inject) => {
  async function get({ userId, authToken, query }) {
    return await $axios.$get(
      `${$config.baseURL}/public/notifications?${query}`,

      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }
  async function markRead({
    userId,
    authToken,
    notificationId = undefined,
    ticketId = undefined,
    markAll = false,
  }) {
    if (markAll) {
      return await $axios.$put(
        `${$config.baseURL}/public/notifications?userId=${userId}`,

        {
          headers: {
            Authorization: `${authToken}`,
            'User-Id': `${userId}`,
          },
        }
      )
    }

    if (ticketId) {
      return await $axios.$put(
        `${$config.baseURL}/public/notifications?ticketId=${ticketId}`,

        {
          headers: {
            Authorization: `${authToken}`,
            'User-Id': `${userId}`,
          },
        }
      )
    }

    return await $axios.$put(
      `${$config.baseURL}/public/notifications?id=${notificationId}`,

      {
        headers: {
          Authorization: `${authToken}`,
          'User-Id': `${userId}`,
        },
      }
    )
  }

  const notificationMS = {
    get,
    markRead,
  }

  inject('notificationMS', notificationMS)
}
