/**
 * register page middleware
 *
 * checks if user is alredy logged in
 * if so, redirects him to /billing with the same params
 */

export default function ({ $auth, redirect, route }) {
  if ($auth.$storage.getState('loggedIn')) {
    return redirect(`/billing/${route.params.plan}`)
  }
}
