
import { mapState, mapActions, mapGetters } from 'vuex'
import { isEmpty } from 'lodash'
import sidebarMixin from '~/mixins/sidebarMixin'

export default {
  name: 'DashboardLayout',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Notifications: () => import('~/components/notifications/Index.vue'),
  },

  filters: {
    hideMonth(subscriptionName) {
      const newSubName = subscriptionName.split(' - Jul20')
      return newSubName[0]
    },
  },

  mixins: [sidebarMixin],

  data() {
    return {
      messages: 0,
      compUserSubscriptions: [],
    }
  },

  computed: {
    ...mapState('dashboardState', {
      loading: 'loading',
    }),
    ...mapState('account', {
      currentUser: 'currentUser',
    }),
    ...mapGetters('account', {
      subscriptions: 'subscriptions',
    }),
    payload() {
      const authUser = this.$auth.$storage.getState('user')

      return {
        userId: authUser.sub,
        authToken: this.$auth.$storage.getUniversal('_token.cookie'),
      }
    },

    userName() {
      const authUser = this.$auth.$storage.getState('user')

      return authUser ? authUser.name.split(' ')[0] : ''
    },
    userImage() {
      const authUser = this.$auth.$storage.getState('user')

      return authUser ? authUser.image : ''
    },
    user() {
      return this.$auth.$storage.getState('user')
    },
    companyName() {
      if (this.currentUser) {
        if (this.currentUser.company) return this.currentUser.company.name
      }

      return false
    },
  },

  async mounted() {
    // this.$vuetify.theme.dark = false
    // this.onResize()
    if (isEmpty(this.$store.state.account.currentUser)) {
      // console.log('fetching account getDataUser')
      await this.getDataUser({ payload: this.payload })
    }

    if (isEmpty(this.$store.state.account.userAccount)) {
      // console.log('fetching account getUserAccount')
      await this.getUserAccount({ payload: this.payload })
    }
  },

  methods: {
    ...mapActions('account', {
      getUserAccount: 'getUserAccount',
      getDataUser: 'getDataUser',
    }),
  },
}
