/* eslint-disable no-console */
import {
  $companyMSCreateBrand,
  $companyMSUpdateBrandAssets,
} from '@/MS/company'

export const state = () => ({
  brand: {
    companyId: '',
    name: '',
    guidelines: '',
    industry: '',
    assets: [],
  },
  socialLinks: [{ type: 'social-link', value: '' }],
  likings: [{ type: 'liking', value: '' }],
  dislikings: [{ type: 'disliking', value: '' }],
  fonts: [{ type: 'fonts', value: '' }],
  guidelines: [{ type: 'guidelines', value: '' }],
  image: [{ type: 'image', value: '' }],
  copy: [{ type: 'copy', value: '' }],
  template: [{ type: 'template', value: '' }],
  logos: [{ type: 'logos', value: '' }],
  animations: [{ type: 'animations', value: '' }],
  colors: [
    { type: 'color', value: '#ffffff' },
    { type: 'color', value: '#ffffff' },
    { type: 'color', value: '#ffffff' },
    { type: 'color', value: '#ffffff' },
  ],
  uploadedFiles: [],
  filesToUpload: [],
  brandId: '',
  fireUpload: false,
  error: false,
  success: false,
  hasUploadedFiles: false,
  willUpload: {
    guidelines: false,
    image: false,
    logos: false,
    template: false,
    animations: false,
    copy: false,
  },
  uploadComplete: {
    guidelines: false,
    image: false,
    logos: false,
    template: false,
    animations: false,
    copy: false,
  },
  loading: false,
})

export const mutations = {
  ADD_PROPERTY(state, payload) {
    state[payload.type].push(payload)
  },
  UPDATE_PROPERTY(state, payload) {
    state[payload.type][payload.index].value = payload.value
  },
  REMOVE_PROPERTY(state, payload) {
    state[payload.type].splice(payload.index, 1)
  },
  SET_BRAND_ATTRIBUTE(state, payload) {
    state.brand[payload.type] = payload.value
  },
  ADD_BRAND_SOCIAL_LINK(state, payload) {
    state.socialLinks.push(payload)
  },
  ADD_BRAND_LIKING(state, payload) {
    state.likings.push(payload)
  },
  ADD_BRAND_DISLIKING(state, payload) {
    state.dislikings.push(payload)
  },
  ADD_BRAND_FONT(state, payload) {
    state.fonts.push(payload)
  },
  UPDATE_SOCIAL_LINK(state, payload) {
    state.socialLinks[payload.index].value = payload.value
  },
  UPDATE_LIKING(state, payload) {
    state.likings[payload.index].value = payload.value
  },
  UPDATE_DISLIKING(state, payload) {
    state.dislikings[payload.index].value = payload.value
  },
  UPDATE_FONT(state, payload) {
    state.fonts[payload.index].value = payload.value
  },
  REMOVE_SOCIAL_LINK(state, payload) {
    state.socialLinks.splice(payload.index, 1)
  },
  REMOVE_LIKING(state, payload) {
    state.likings.splice(payload.index, 1)
  },
  REMOVE_DISLIKING(state, payload) {
    state.dislikings.splice(payload.index, 1)
  },
  REMOVE_FONTS(state, payload) {
    state.fonts.splice(payload.index, 1)
  },
  SET_BRAND_COLOR(state, payload) {
    state.colors[payload.index].value = payload.color.hex8
  },
  ADD_BRAND_COLOR(state, payload) {
    state.colors.push(payload)
  },
  REMOVE_BRAND_COLOR(state) {
    state.colors.pop()
  },
  ADD_UPLOADED_FILE(state, payload) {
    state.uploadedFiles.push(payload)
    state.willUpload[payload.type] = true

    // console.log('uploaded files', state.uploadedFiles);
  },
  ADD_FILE_TO_UPLOAD(state, payload) {
    state.filesToUpload.push(payload)
    // console.log('files to upload', state.filesToUpload);
  },
  SET_BRAND_ID(state, payload) {
    state.brandId = payload
  },
  SET_FIRE_UPLOAD(state) {
    state.fireUpload = true
  },
  SET_SUCCESS(state) {
    state.success = true
  },
  SET_HAS_UPLOADED_FILES(state) {
    state.hasUploadedFiles = true
  },
  RESET_STATE(state) {
    state.brand = {
      companyId: '',
      name: '',
      guidelines: '',
      industry: '',
      assets: [],
    }
    state.socialLinks = [{ type: 'social-link', value: '' }]
    state.likings = [{ type: 'liking', value: '' }]
    state.dislikings = [{ type: 'disliking', value: '' }]
    state.fonts = [{ type: 'fonts', value: '' }]
    state.colors = [
      { type: 'color', value: '#ffffff' },
      { type: 'color', value: '#ffffff' },
      { type: 'color', value: '#ffffff' },
      { type: 'color', value: '#ffffff' },
    ]
    state.uploadedFiles = []
    state.filesToUpload = []
    state.brandId = 0
    state.fireUpload = false
    state.error = false
    state.success = false
    state.hasUploadedFiles = false
  },
  RESET_ERROR_STATE(state) {
    state.error = false
  },
  REMOVE_FILE_TO_UPLOAD(state, payload) {
    const index = state.filesToUpload.findIndex(
      (file) => file.filename === payload.file
    )

    state.filesToUpload.splice(index, 1)
  },
  SET_DATA(state, payload) {
    payload.forEach((element) => {
      state[element.property] = element.data
    })
  },
}

export const actions = {
  async createNewBrand({ commit, state }, payload) {
    // console.log('payload store', payload)
    commit('SET_BRAND_ATTRIBUTE', {
      type: 'companyId',
      value: payload.companyId,
    })
    if (!state.brand.guidelines || !state.brand.industry) {
      // state.error = true

      return false
    }
    const assets = []
    // console.log('validate passed')
    // state.socialLinks.map((asset) => state.brand.assets.push(asset));
    state.socialLinks.forEach((asset) => {
      if (asset.value) {
        // state.brand.assets.push(asset)
        assets.push(asset)
      }
    })
    state.likings.forEach((asset) => {
      if (asset.value) {
        // state.brand.assets.push(asset)
        assets.push(asset)
      }
    })
    state.dislikings.forEach((asset) => {
      if (asset.value) {
        // state.brand.assets.push(asset)
        assets.push(asset)
      }
    })
    state.fonts.forEach((asset) => {
      if (asset.value) {
        // state.brand.assets.push(asset)
        assets.push(asset)
      }
    })

    state.guidelines.forEach((asset) => {
      if (asset.value) {
        // state.brand.assets.push(asset)
        assets.push(asset)
      }
    })

    state.image.forEach((asset) => {
      if (asset.value) {
        // state.brand.assets.push(asset)
        assets.push(asset)
      }
    })

    state.copy.forEach((asset) => {
      if (asset.value) {
        // state.brand.assets.push(asset)
        assets.push(asset)
      }
    })

    state.template.forEach((asset) => {
      if (asset.value) {
        // state.brand.assets.push(asset)
        assets.push(asset)
      }
    })

    state.logos.forEach((asset) => {
      if (asset.value) {
        // state.brand.assets.push(asset)
        assets.push(asset)
      }
    })

    state.animations.forEach((asset) => {
      if (asset.value) {
        // state.brand.assets.push(asset)
        assets.push(asset)
      }
    })
    // state.likings.map((asset) => state.brand.assets.push(asset));
    // state.dislikings.map((asset) => state.brand.assets.push(asset));
    // state.colors.map((asset) => state.brand.assets.push(asset))
    state.colors.map((asset) => assets.push(asset))

    commit('SET_BRAND_ATTRIBUTE', { type: 'assets', value: assets })

    const newPayload = {
      userId: payload.userId,
      authToken: payload.authToken,
      payload: {
        companyId: payload.companyId,
        ...state.brand,
      },
    }
    // console.log('newPayload', newPayload)
    try {
      const response = await $companyMSCreateBrand(newPayload)
      if (!response.success) {
        throw new Error(`Error upon creating brand: ${response.error}`)
      }
      const brand = response.data

      if (!state.hasUploadedFiles) {
        commit('SET_SUCCESS')
      } else {
        commit('SET_BRAND_ID', brand.id)
        commit('SET_FIRE_UPLOAD')
      }
    } catch (error) {
      // console.error(error)
      state.error = true

      const user = this.$auth.$storage.getState('user')

      this.$sentry.setContext('user', user)
      this.$sentry.setTag('context', 'brands store')
      this.$sentry.captureException(error)

      return false
    }
  },
  async uploadAssetFiles({ commit, state }, file) {
    /* const payload = { data: state.uploadedFiles }; */
    // const payload = { data: [file] }
    // const payload = { data: [file] }
    const { brandId, filesToUpload, uploadedFiles } = state
    const payload = {
      userId: this.$auth.$storage.getState('user').sub,
      authToken: this.$auth.$storage.getCookie('_token.cookie'),
      payload: {
        assets: [file],
      },
      brandId,
    }
    try {
      const response = await $companyMSUpdateBrandAssets(payload)

      if (
        response.success &&
        (filesToUpload.length === uploadedFiles.length ||
          (!filesToUpload.length && uploadedFiles.length))
      ) {
        commit('SET_DATA', [{ property: 'loading', data: false }])
        commit('SET_SUCCESS')
      }
    } catch (error) {
      // console.error(error)
      const user = this.$auth.$storage.getState('user')

      this.$sentry.setContext('user', user)
      this.$sentry.setTag('context', 'brands store')
      this.$sentry.captureException(error)
      return error
    }
  },
  addUploadedFile({ commit, dispatch }, payload) {
    commit('ADD_UPLOADED_FILE', payload)
    // const { filesToUpload, uploadedFiles } = state
    /* if (filesToUpload.length === uploadedFiles.length) { */
    dispatch('uploadAssetFiles', payload)
    /* } */
  },
  checkUploadedFiles({ commit, state }) {
    // console.log('checking uploaded files')
    // const { filesToUpload, uploadedFiles } = state;
    // if (filesToUpload.length === uploadedFiles.length) {
    //   commit('SET_SUCCESS');
    // }
  },
}

export const getters = {
  GET_BRAND_NAME: (state) => state.brand.name,
  GET_BRAND_GUIDELINES: (state) => state.brand.guidelines,
  GET_BRAND_INDUSTRY: (state) => state.brand.industry,
  GET_BRAND_SOCIAL_LINKS: (state) => state.socialLinks,
  GET_BRAND_COLORS: (state) => state.colors,
  GET_BRAND_ID: (state) => state.brandId,
  GET_SAVING_STATE: (state) => state.saving,
  GET_SUCCESS_STATE: (state) => state.success,
}
