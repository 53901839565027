export const state = () => ({
  isAdvancedTicket: false,
})

export const getters = {}

export const mutations = {
  setData(state, payload) {
    payload.forEach((element) => {
      state[element.property] = element.data
    })
  },
}

export const actions = {}
