export default async function (context) {
  const user = context.$auth.$storage.getState('user')

  if (user) {
    if (!user.isEmployee) {
      const payload = {
        userId: user.sub,
        authToken: context.$auth.$storage.getUniversal('_token.cookie'),
      }

      const { data } = await context.$accountMS.show(payload)

      if (!data.length) {
        return context.redirect('/account-issues')
      }

      const [accountInfo] = data

      if (
        (accountInfo.status === null ||
          accountInfo.status === 'Past Due' ||
          accountInfo.status === 'Active(ex)' ||
          accountInfo.status === 'Cancelled') &&
        !accountInfo.features.length &&
        !accountInfo.subscriptions.length
      ) {
        return context.redirect('/account-issues')
      }
    }
  }
}
