export const state = () => ({
  gettingFeatures: false,
  allFeatures: [],
  userAccount: {},
  userSubscriptions: [],
  userFeatures: [],
  features: {},
  mostUsedCodeList: [],
  isRepurposing: undefined,
  usedFeatures: [],
  trendingList: [],
})

export const getters = {
  getCounter(state) {
    return state.counter
  },
}

export const mutations = {
  setData(state, payload) {
    payload.forEach((element) => {
      state[element.property] = element.data
    })
  },

  arraysToObject(state, payload) {
    Object.keys(state.payload).forEach((key) => {
      const arr = state.payload[key]
      const obj = arr.reduce(function (acc, cur) {
        acc[cur.feature_code] = cur
        return acc
      }, {})
      state.payload[key] = obj
    })
  },
}

export const actions = {
  fetchCounter({ state }) {
    // make request
    const res = { data: 10 }
    state.counter = res.data
    return res.data
  },
}
