export const state = () => ({
  userRegister: {
    firstName: '',
    surname: '',
    email: '',
    password: '',
    confirmPassword: '',
  },
  companyInfo: {
    name: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    phone: '',
    tax: '',
  },
  billing: {},
})
