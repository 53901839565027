import { set } from 'vue'
export const state = () => ({
  activeImage: {},
})

export const getters = {
  // getUser: (state) => state.user,
}

export const mutations = {
  SET_ACTIVE_IMAGE(state, payload) {
    state.activeImage = payload.file
  },

  ADD_ACTIVE_IMAGE_DATA(state, { field, data }) {
    set(state.activeImage, field, data)
  },
}

export const actions = {}
