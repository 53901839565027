export default ({ app, $axios, $config }, inject) => {
  // make petition to gateway for login
  async function login({ email, password }) {
    const basicAuth = 'Basic ' + btoa(email + ':' + password)

    try {
      const response = await $axios.post(
        `${$config.baseURL}/login`,
        {},
        {
          headers: {
            Authorization: basicAuth,
          },
        }
      )

      if (!response.data.success) {
        throw new Error('Error in petition to login')
      }

      return {
        success: true,
        data: response.data.data,
      }
    } catch (error) {
      // todo: catch this error with logRocket
      // eslint-disable-next-line no-console
      console.error(error.message)

      return {
        success: false,
        error: error.message,
      }
    }
  }
  async function createUser({ body }) {
    const response = await $axios.$post(`${$config.baseURL}/public/users`, body)

    // if (!response.success) {
    //   throw new Error(`Error couldn't create user`)
    // }

    return response
  }

  async function checkAuth({ authToken }) {
    return await $axios.$post(
      `${$config.baseURL}/auth`,
      {},
      {
        headers: {
          Authorization: `${authToken}`,
        },
        withCredentials: true,
      }
    )
  }

  async function sendInvitedUserEmails({ userId, authToken }) {
    const response = await $axios.$post(
      `${$config.baseURL}/public/users/welcome-invited/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        withCredentials: true,
      }
    )

    // if (!response.success) {
    //   throw new Error(`Error couldn't create user`)
    // }

    return response
  }

  const thefutures = {
    login,
    createUser,
    checkAuth,
    sendInvitedUserEmails,
  }

  inject('thefutures', thefutures)
}
