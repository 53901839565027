
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import NotificationItem from '~/components/notifications/Item.vue'
import notificationsJSON from '~/static/notificationJSON/notification.json'
// import Echo from 'laravel-echo';

const { title } = document
export default {
  name: 'NotificationsComponent',

  components: {
    Notification: NotificationItem,
  },

  data() {
    return {
      messages: notificationsJSON.length,
      // notifications: [],
      notifChannel: [
        'notifications:commentCreated',
        'ticket:update',
        'notification:annotationCreated',
        'notification:annotationUpdate',
        'notification:notesCreated',
        'notification:taskCreated',
        'notification:createExpedite',
        'notification:updateTicket',
        'notification:deletedTicket',
        'notification:linkCreated',
        'notification:designSubmitted',
        'notification:approveSeveral',
      ],
      loading: false,
      title,
    }
  },

  computed: {
    ...mapState('account', ['currentUser']),
    ...mapState('notifications', ['notifications']),
    ...mapGetters('notifications', ['unreadNotifications']),

    unreadNotificationsCount() {
      return this.notifications.filter((notification) => !notification.readAt)
        .length
    },

    user() {
      return this.$auth.$storage.getState('user')
    },
  },
  // watch: {
  //   unreadNotificationsCount(count) {
  //     if (count) {
  //       // document.title = `${this.title} (${count})`
  //       // console.log(this.title)
  //     }
  //   },
  // },
  async mounted() {
    await this.initSocket()
    await this.getNotifications()
  },

  methods: {
    ...mapMutations('toast', {
      setToast: 'SET_TOAST_NOTIFICATION',
    }),
    ...mapActions('notifications', {
      fetchNotifications: 'fetchNotifications',
    }),

    async getNotifications() {
      this.loading = true

      try {
        await this.fetchNotifications()
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error.message)

        const user = this.$auth.$storage.getState('user')

        this.$sentry.setContext('user', user)
        this.$sentry.setTag('context', 'notifications index')
        this.$sentry.captureException(error)

        this.setToast({
          type: 'error',
          text: `There was a problem getting notifications: ${error.message}`,
          timeout: 5000,
        })
      } finally {
        this.loading = false
      }
    },

    async markAllAsRead() {
      this.loading = true
      let userId

      if (
        this.user.roles.includes('admin') ||
        this.user.roles.includes('content-manager')
      ) {
        userId = 7
      } else {
        userId = this.$auth.$storage.getState('user').sub
      }

      try {
        const payload = {
          userId,
          authToken: this.$auth.$storage.getUniversal('_token.cookie'),
          markAll: true,
        }

        const response = await this.$notificationMS.markRead(payload)

        if (!response.success) {
          throw new Error(
            `Error marking all notifications as read: ${response.message}`
          )
        }

        await this.getNotifications()
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)

        const user = this.$auth.$storage.getState('user')

        this.$sentry.setContext('user', user)
        this.$sentry.setTag('context', 'notifications index')
        this.$sentry.captureException(error)

        this.setToast({
          text: `Something went wrong updating the annotations: ${error.message}`,
          type: 'error',
          timeout: 5000,
        })
      } finally {
        this.loading = false
      }
    },

    initSocket() {
      // const userId = this.$auth.$storage.getState('user').sub
      const vm = this
      this.socket = this.$nuxtSocket({
        // nuxt-socket-io opts:
        name: 'notificactions', // Use socket "home"

        // socket.io-client opts:
        reconnection: true,
        withCredentials: false,
        rejectUnauthorized: false,
        transports: ['websocket', 'polling'],
      })

      this.socket.on('connect', () => {
        /***
         * if user is pl or admin, get notifications for
         * user-id 7, else, uses the user's id to join the
         * socket
         */
        if (
          this.user.roles.includes('content-manager') ||
          this.user.roles.includes('admin')
        ) {
          this.socket.emit('notifications:join', 7)

          // eslint-disable-next-line no-console
          console.log(`Notifications Socket Connected for id: ${7}`)
        } else {
          this.socket.emit('notifications:join', this.user.sub)

          // eslint-disable-next-line no-console
          console.log(`Notifications Socket Connected for id: ${this.user.sub}`)
        }
      })

      this.notifChannel.forEach((channel) => {
        this.socket.on(channel, async (...args) => {
          const { message, link } = args[0].data

          this.setToast({
            text: message,
            type: 'primary',
            link,
            timeout: 9000,
          })

          await vm.getNotifications()
        })
      })
    },
  },
}
