/***
 * fetches ticket data,
 * if there's an error
 * redirects to tickets/open
 *
 * the error usually is 401 (unauth)
 */

// eslint-disable-next-line require-await
export default async function (context) {
  const user = context.$auth.$storage.getState('user')

  if (user) {
    const { id: ticketId } = context.route.params
    const userId = user.sub
    const payload = {
      userId,
      authToken: context.$auth.$storage.getUniversal('_token.cookie'),
      ticketId,
    }

    try {
      const { success, message } = await context.$ticketMS.show(payload)

      if (!success) {
        throw new Error(message)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)

      context.redirect('/tickets/open')
    }
  }
}
