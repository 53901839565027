
import { mapMutations, mapActions } from 'vuex'

export default {
  name: 'NofiticationItem',
  props: {
    notification: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    isPl: {
      type: Boolean,
      required: true,
      default: () => false,
    },
  },

  data() {
    return {
      loading: false,
    }
  },

  computed: {
    src() {
      if (this.notification.doneBy && this.notification.doneBy.image) {
        return this.notification.doneBy.image
      }
      return 'https://dgkceoarlalez.cloudfront.net/various/notiIcon.png'
    },

    to() {
      const link =
        `${this.notification.link}?read=${this.notification.id}`.split('/')
      const positionWord = link.findIndex((el) => el === 'tickets')
      if (positionWord < 2) {
        return `${this.notification.link}?read=${this.notification.id}`
      }
      return `/${link
        .filter((el, index) => index > positionWord - 1)
        .join('/')}`
      // return `${this.notification.link}?read=${this.notification.id}`
    },

    message() {
      return `${this.notification.message}`
    },

    dateTime() {
      if (this.isPl) {
        return this.formatDate(this.notification.createdAt)
      }

      if (!this.notification.diff_time) {
        return '1 second ago'
      }

      return this.notification.diff_time
    },
  },

  methods: {
    ...mapMutations('toast', {
      setToast: 'SET_TOAST',
    }),
    ...mapActions('notifications', {
      fetchNotifications: 'fetchNotifications',
    }),

    formatDate(value) {
      const date = new Date(value)

      const monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ]
      const time = this.transformHours(date)

      const newDate = `${
        monthNames[date.getMonth()]
      } ${date.getDate()}, ${time}`

      return newDate
    },

    transformHours(date) {
      let hours = date.getHours()
      let minutes = date.getMinutes()
      const ampm = hours >= 12 ? 'pm' : 'am'
      hours %= 12
      hours = hours || 12 // the hour '0' should be '12'
      minutes = minutes < 10 ? `0${minutes}` : minutes
      const strTime = `${hours}:${minutes} ${ampm}`
      return strTime
    },

    async getNotifications() {
      this.loading = true

      try {
        await this.fetchNotifications()
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error.message)

        const user = this.$auth.$storage.getState('user')

        this.$sentry.setContext('user', user)
        this.$sentry.setTag('context', 'notifications index')
        this.$sentry.captureException(error)

        this.setToast({
          type: 'error',
          text: `There was a problem getting notifications: ${error.message}`,
          timeout: 5000,
        })
      } finally {
        this.loading = false
      }
    },

    // eslint-disable-next-line require-await
    async readNotification(notification) {
      if (!notification.readAt) {
        try {
          const payload = {
            userId: this.$auth.$storage.getState('user').sub,
            authToken: this.$auth.$storage.getUniversal('_token.cookie'),
            notificationId: notification.id,
          }

          const response = await this.$notificationMS.markRead(payload)

          if (response.success) {
            await this.getNotifications()

            const user = this.$auth.$storage.getState('user');
            if(user.isEmployee) {
              this.$router.push(this.to)
            } else {
              window.location = this.to;
            }
            
          } else {
            throw new Error(`Error reading notifications: ${response.message}`)
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error.message)

          const user = this.$auth.$storage.getState('user')

          this.$sentry.setContext('user', user)
          this.$sentry.setTag('context', 'notification item')
          this.$sentry.captureException(error)

          this.setToast({
            message: `Something went wrong updating the annotation: ${error.message}`,
            type: 'error',
            timeout: 5000,
          })
        }
      } else {
            const user = this.$auth.$storage.getState('user');

            if(user.isEmployee) {
              this.$router.push(this.to)
            } else {
              window.location = this.to;
            }
      }
    },

    async markAsRead(id) {
      this.loading = true

      try {
        const payload = {
          userId: this.$auth.$storage.getState('user').sub,
          authToken: this.$auth.$storage.getUniversal('_token.cookie'),
          notificationId: id,
        }

        const response = await this.$notificationMS.markRead(payload)

        if (!response.success) {
          throw new Error(`Error reading notification: ${response.message}`)
        }

        await this.getNotifications()
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)

        const user = this.$auth.$storage.getState('user')

        this.$sentry.setContext('user', user)
        this.$sentry.setTag('context', 'notification item')
        this.$sentry.captureException(error)

        this.setToast({
          message: `Something went wrong updating the annotation: ${error.message}`,
          type: 'error',
          timeout: 5000,
        })
      } finally {
        this.loading = false
      }
    },
  },
}
