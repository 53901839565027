export const state = () => ({
  snackbar: {
    show: false,
    text: '',
    type: '',
    timeout: 3000,
    position: 'bottom-right',
  },
  snackbarNotification: {
    show: false,
    text: '',
    type: 'primary',
    timeout: 3000,
    link: '',
  },
})

export const mutations = {
  SET_TOAST(
    state,
    { type, message, timeout = 3000, show = true, position = 'bottom-right' }
  ) {
    state.snackbar = {
      show,
      type,
      message,
      timeout,
      position,
    }
  },
  SET_SHOW(state, { show }) {
    state.snackbar.show = show
  },
  SET_TOAST_NOTIFICATION(
    state,
    { type, text, timeout = 3000, show = true, link }
  ) {
    state.snackbarNotification = {
      show,
      type,
      text,
      timeout,
      link,
    }
  },
  SET_SHOW_NOTIFICATION(state, { show }) {
    state.snackbarNotification.show = show
  },
}

export const getters = {
  showSnackbarNotification: (state) => state.snackbarNotification.show,
}
